import React, { useEffect, useState } from "react";
import { Form, Input, Select, DatePicker, Skeleton } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify, makeRequestStateless } from "@utils/helpers";
import { getPricingSetting, updatePricingSetting, getPriceDependencies } from "../requests";
const moment = require('moment');

const formName = "updatePricingSetting";

const EditPricingSetting = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState("");
  const [deps, setDeps] = useState({
    bookingTypes: [],
    petTypes: [],
  });



  useEffect(() => {
    getSelectFieldsData();
    getData();

    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getPricingSetting, props.id, onDataSuccess, onError);
  }

  const onDataSuccess = (res) => {
    let data = res.object;
    data.date_from = moment(data.date_from);
    setData(data);
  }


  const getSelectFieldsData = () => {
    makeRequestStateless(getPriceDependencies, null, onDependencySuccess, null);
  }

  const onDependencySuccess = (data, res) => {
    setDeps({
      bookingTypes: data.bookingTypes,
      petTypes: data.petTypes,
    });
  }

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object.date_from = data.date_from.format("YYYY-MM-DD");
    payload.id = props.id;
    makeRequest(setLoader, updatePricingSetting, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Price", res.msg);
    props.onCreated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['date_from'] = err.date_from;
    errorList['booking_type_id'] = err.booking_type_id;
    errorList['pet_type_id'] = err.pet_type_id;
    errorList['price'] = err.price;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------



  return (

    <ModalComponent mainTitle="Update" subTitle="Pricing" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>
      { !data ? <Skeleton /> :
        <Form
          layout="horizontal"
          name={ formName }
          onFinish={ onFinish }
          initialValues={ data }
        >
          <Form.Item gutter={ 2 } name="date_from" rules={ rules.date_from } label="Effective Date" className="da-mb-16"
            { ...getErrorProps(errors['date_from']) }
          >
            <DatePicker format="DD/MM/YYYY" className="da-w-100" />
          </Form.Item>
          <Form.Item
            gutter={ 4 }
            name="booking_type_id" label="Booking Type" rules={ rules.booking_type_id } className="da-mb-16"
            { ...getErrorProps(errors['booking_type_id']) }
          >
            <Select
              showSearch
               filterOption={(d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0}
              placeholder="Select a Type"
              options={ deps.bookingTypes }
            />
          </Form.Item>
          <Form.Item gutter={ 34 } name="pet_type_id" label="Pet Type" rules={ rules.pet_type_id } className="da-mb-16"
            { ...getErrorProps(errors['pet_type_id']) }
          >
            <Select
              showSearch
               filterOption={(d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0}
              placeholder="Select a Pet Type"
              options={ deps.petTypes }
            />
          </Form.Item>

          <Form.Item name="pets_shairing" rules={ rules.pets_shairing } label="Pet Sharing #" className="da-mb-16"
            { ...getErrorProps(errors['pets_shairing']) }
          >
            <Input type="number" placeholder="Pet Sharing #" />
          </Form.Item>

          <Form.Item gutter={ 6 } name="price" rules={ rules.price } label="Per Day Price" className="da-mb-16"
            { ...getErrorProps(errors['price']) }
          >
            <Input type="number" placeholder="Per Day Price" />
          </Form.Item>

        </Form>
      }
    </ModalComponent>
  );


}

export default EditPricingSetting

const rules = {
  date_from: [
    { required: true, message: 'Please select date!', },
  ],
  booking_type_id: [
    { required: true, message: 'Please select booking type!', },
  ],
  pet_type_id: [
    { required: true, message: 'Please select pet type!', },
  ],
  pets_shairing: [
    { required: true, message: 'Please enter sharing', },
  ],
  price: [
    { required: true, message: 'Please enter price', },
  ],

};
