import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HeaderComponent, BodyComponent, TableComponent, ActionComponent, CreateButton } from "@comps/components";
import { makeRequest, notify, removeById } from "@utils/helpers";
import { getBookings, deleteBooking } from "./requests";
import { ContainerOutlined, PoundCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import StripePay from './../../paymentManagement/StripePay';

const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Bookings",
    breadcrumb: [
      {
        name: "Bookings",
        path: "/booking-management/bookings"
      }
    ]
  }
}

const IndexClientBooking = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);

  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'client_name',
      title: 'Client Name',
      dataIndex: 'client_name',
      sorter: true,
    },
    {
      key: 'booking_type_name',
      title: 'Animal Boarding',
      dataIndex: 'booking_type_name',
      sorter: false,
    },
    {
      key: 'start_date',
      title: 'Day In',
      dataIndex: 'start_date',
      sorter: false,
      render: (each) => moment(each).format('DD/MM/YYYY')
    },
    {
      key: 'end_date',
      title: 'Day out',
      dataIndex: 'end_date',
      sorter: false,
      render: (each) => each ? moment(each).format('DD/MM/YYYY') : null
    },
    {
      key: 'total_cats',
      title: 'Cats',
      sorter: false,
      render: (each) => <><Button onClick={ () => showPets(each.id) }>{ each.total_cats }</Button></>
    },
    {
      key: 'total_dogs',
      title: 'Dogs',
      sorter: false,
      render: (each) => <><Button onClick={ () => showPets(each.id) }>{ each.total_dogs }</Button></>
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => ActionComponentEx(record)
    },
  ];

  const onPayment = (each) => {
    setChildComponent(<StripePay data={ each } onCreated={ onCreated } />);
  }

  const onCreated = (response) => {
    setChildComponent(null);
    getAllBookings();
  }

  const ActionComponentEx = (record) => {
    return (
      <>
        <Tooltip title="Pet Care Plan" ><Button onClick={ () => onViewCarePlan(record) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><ContainerOutlined /></Button></Tooltip>
        <Tooltip title="View Invoice" ><Button onClick={ () => onViewInvoice(record) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: '#50a5f1', fontSize: '18px' } }><Icon icon="uil-invoice" /></Button></Tooltip>
        {/* onDelete={ onDelete } */ }
        <ActionComponent each={ record }  >
        </ActionComponent>
        { isFutureDate(new Date(record.start_date)) ?
          <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } >
          </ActionComponent> : null }
        { record && !record.deposit_paid ?
          <Tooltip title="Pay Now" ><Button onClick={ () => onPayment(record) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><PoundCircleOutlined /></Button></Tooltip> : null }
      </>
    );
  }

  const onEdit = (record) => {
    history.push(`/booking-management/bookings/edit/${record.id}`);
  }


  useEffect(() => {
    getAllBookings();
    // eslint-disable-next-line
  }, [pagination, filters]);

  const getAllBookings = () => {
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters
    };
    makeRequest(setLoader, getBookings, payload, onSuccess, null);
  }

  const showPets = (id) => {
    history.push(`/booking-management/bookings/pets/${id}`);
  }

  const onViewInvoice = (each) => {
    history.push(`/invoice/${each.invoice}`);
  }

  function isFutureDate(date) {
    var currentDate = new Date();
    var futureDate = new Date(currentDate.setDate(currentDate.getDate() + 14));

    return date > futureDate ? true : false;
  }

  const onViewCarePlan = (each) => {
    history.push(`/bookingcareplan/${each.id}`);
  }

  const onSuccess = (response) => {
    setTotalRecords(response.recordsTotal);
    setDataSource(response.data);
  }


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'descend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  // Create component modal
  const onCreate = () => {
    history.push(`/booking-management/bookings/create`);
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deleteBooking, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify("Booking", msg.msg)
  }

  const onError = (error, msg) => {
    //
  }

  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
        <CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } text="Make Booking" onClick={ onCreate } />
      </HeaderComponent>
      <BodyComponent >
        <div className="da-mt-32">
          <p>if you want your animals in separate kennels please make 2 separate bookings</p>
          <TableComponent loader={ loader ? true : false } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
        </div>
      </BodyComponent>
    </>
  );
}

export default IndexClientBooking;
