import { get, post, del, put } from "@utils/axios";

export const getSettings = () => {
  return get(`configs-management/get-settings`);
}

export const getInvoice = (number) => {
  return get(`/invoice-management/get-invoice/` + number);
}

export const getPetBookingCarePlan = (id) => {
  return get(`booking-management/bookings/careplan/` + id);
}