import { get, post, del, put, postFd } from "@utils/axios";

const api = "clients-management/clients";

export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getClients = (payload) => {
    return get(api, payload);
}

export const getClient = (id) => {
    return get(`${api}/${id}`);
}

export const createClient = (payload) => {
    return post(api, payload);
}

export const updateClient = (payload) => {
    return put(`${api}/${payload.object.id}`, payload);
}

export const deleteClient = (id) => {
    return del(`${api}/${id}`);
}
export const uploadImageRequest = (id) => {
    // return del(`${api}/${id}`);
}
export const updateClientNote = (payload) => {
    return post(api + "/update-notes", payload);
}

export const deleteImage = (id) => {
    return get(`delete-image/` + id);
}