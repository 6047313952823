import React, { useState, useEffect } from "react";
import { ButtonComponent, } from "@comps/components";
import { Row, Col, Checkbox } from "antd";
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { getAllowDay, updateAllowDays, updateDisableDates, getDisableDatesRequest } from "./requests";
import { makeRequest, notify } from '@utils/helpers';
import { useSelector } from 'react-redux';
const moment = require('moment');

const AllowDays = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [allowDays, setAllowDays] = useState([]);
  const [disableDates, setDisableDates] = useState([]);


  useEffect(() => {
    getAllowDays();
    getDisableDates();
  }, []);

  const getAllowDays = () => {
    makeRequest(setLoader, getAllowDay, 1, onDaySuccess, null);
  }

  const onDaySuccess = (data, res) => {
    setAllowDays(data.object);
  }

  const getDisableDates = () => {
    makeRequest(setLoader, getDisableDatesRequest, null, onDateSuccess, null);
  }

  const onDateSuccess = (data, res) => {
    setDisableDates(data.map(each => {
      return new Date(each.date);
    }));
  }

  const onSaveAllowDays = () => {
    let payload = {
      id: 1,
      "object": {
        sunday: allowDays.sunday,
        monday: allowDays.monday,
        tuesday: allowDays.tuesday,
        wednesday: allowDays.wednesday,
        thursday: allowDays.thursday,
        friday: allowDays.friday,
        saturday: allowDays.saturday,
      }
    }
    makeRequest(setLoader, updateAllowDays, payload, onDayUpdateSuccess, null);
  }

  const onDayUpdateSuccess = (data, res) => {
    notify('success', 'Allow days updated successfully');
  }

  const saveDisableDates = () => {
    let payload = {
      "dates": disableDates.map(date => {
        return date.unix ? moment.unix(date.unix).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD')
      })
    }
    makeRequest(setLoader, updateDisableDates, payload, onDateUpdateSuccess, null);
  }

  const onDateUpdateSuccess = (data, res) => {
    notify('success', 'Disable dates updated successfully');
  }

  if (allowDays.length === 0) {
    return null;
  }

  return (
    <>
      <div className="rightBox">
        <style>{ `.ant-checkbox:hover .ant-checkbox-inner,.ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${crmStyle.bg_color} ;
            border-color: ${crmStyle.bg_color};
          }.ant-checkbox-wrapper:focus-within .ant-checkbox-inner {border-color: ${crmStyle.bg_color};}
          `}</style>
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 12 }><h4>Allowed Days</h4></Col>
              <Col span={ 12 } className="da-text-right"></Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 } style={ { background: '#F8F9FA' } }>
              <Col span={ 12 }><h5>Sunday</h5></Col>
              <Col span={ 12 }><Checkbox
                onChange={ (e) => { setAllowDays({ ...allowDays, sunday: e.target.checked }) } }
                checked={ allowDays.sunday }
              /></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-12" >
              <Col span={ 12 }><h5>Monday</h5></Col>
              <Col span={ 12 }><Checkbox
                onChange={ (e) => { setAllowDays({ ...allowDays, monday: e.target.checked }) } }
                checked={ allowDays.monday } /></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-12" style={ { background: '#F8F9FA' } }>
              <Col span={ 12 }><h5>Tuesday</h5></Col>
              <Col span={ 12 }><Checkbox
                onChange={ (e) => { setAllowDays({ ...allowDays, tuesday: e.target.checked }) } }
                checked={ allowDays.tuesday } /></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-12">
              <Col span={ 12 }><h5>Wednesday</h5></Col>
              <Col span={ 12 }><Checkbox
                onChange={ (e) => { setAllowDays({ ...allowDays, wednesday: e.target.checked }) } }
                checked={ allowDays.wednesday } /></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-12" style={ { background: '#F8F9FA' } }>
              <Col span={ 12 }><h5>Thursday</h5></Col>
              <Col span={ 12 }><Checkbox
                onChange={ (e) => { setAllowDays({ ...allowDays, thursday: e.target.checked }) } }
                checked={ allowDays.thursday } /></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-12">
              <Col span={ 12 }><h5>Friday</h5></Col>
              <Col span={ 12 }><Checkbox
                onChange={ (e) => { setAllowDays({ ...allowDays, friday: e.target.checked }) } }
                checked={ allowDays.friday } /></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-12" style={ { background: '#F8F9FA' } }>
              <Col span={ 12 }><h5>Saturday</h5></Col>
              <Col span={ 12 }><Checkbox
                onChange={ (e) => { setAllowDays({ ...allowDays, saturday: e.target.checked }) } }
                checked={ allowDays.saturday } /></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-24 da-text-center">
              <Col span={ 24 }>
                <ButtonComponent style={ { background: crmStyle.bg_color, border: crmStyle.border_color, width: '150px' } } onClick={ onSaveAllowDays } type="primary" loader={ loader }>Update</ButtonComponent>
              </Col>
            </Row>
          </Col>
        </Row>
        <h3>Disable Dates</h3>
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <DatePicker
              value={ disableDates }
              minDate={ moment().toDate() }
              onChange={ (date) => { setDisableDates(date) } }
              format={ "DD/MM/YYYY" }
              placeholder="Chose Dates To Disable"
              containerStyle={ {
                width: "100%"
              } }
              style={ { width: '100%', height: '40px' } }
              multiple
              plugins={ [
                <DatePanel />
              ] }
            />
          </Col>
          <Col span={ 24 } className="da-mt-24 da-text-center">
            <ButtonComponent style={ { background: crmStyle.bg_color, border: crmStyle.border_color, width: '150px' } } onClick={ saveDisableDates } type="primary" >Update</ButtonComponent>
          </Col>
        </Row>



      </div>
    </>
  );
}

export default AllowDays;