import React, { useState, useEffect } from "react";
import { TableComponent, HeaderComponent, BodyComponent, ActionComponent } from "@comps/components";
import { useParams, useHistory } from "react-router-dom";
import { makeRequest, notify } from '@utils/helpers';
import { Row, Col, Tooltip, Button, Select } from "antd";
import { useSelector } from 'react-redux';
import { getPetDetailRequest } from '../requests'
import { ContainerOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Booked Pets",
    breadcrumb: [
      {
        name: "Booked Pets",
        path: "/"
      }
    ]
  }
}


const PetDetailIndex = () => {
  const history = useHistory();
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { date, petType } = useParams();
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [startRecord, setStartRecord] = useState(1);
  const [endRecord, setEndRecord] = useState(pageSize);
  const [bookedKennels, setBookedKennels] = useState(0);
  const [availableKennels, setAvailableKennels] = useState(0);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: pageSize,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      sorter: false,
      render: (each) => moment(each).format('DD/MM/YYYY')
    },
    {
      key: 'pet_name',
      title: 'Pet Name',
      dataIndex: 'pet_name',
      sorter: false,
    },
    {
      key: 'pet_type',
      title: 'Pet Type',
      dataIndex: 'pet_type',
      sorter: false,
    },
    {
      key: 'owner_name',
      title: 'Owner Name',
      dataIndex: 'owner_name',
      sorter: false,
    },
    {
      key: 'checked_in',
      title: 'Check In',
      dataIndex: 'checked_in',
      sorter: false,
      render: (each) => each ? moment(each).format('DD/MM/YYYY') : null
    },
    {
      key: 'checked_out',
      title: 'Check Out',
      dataIndex: 'checked_out',
      sorter: false,
      render: (each) => each ? moment(each).format('DD/MM/YYYY') : null
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <><Tooltip title="View Invoice" ><Button onClick={ () => history.push(`/invoice/${record.invoice}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: '#50a5f1', fontSize: '18px' } }><Icon icon="uil-invoice" /></Button></Tooltip>

        <Tooltip title="Pet Care Plan" ><Button onClick={ () => history.push(`/pet-management/pets/careplan/${record.pet_id}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><ContainerOutlined /></Button></Tooltip>
        <Tooltip title="Pet Profile" ><Button onClick={ () => history.push(`/pet-management/pets/view/${record.pet_id}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><Icon icon="material-symbols:pets" /></Button></Tooltip>
      </>
    },
  ];

  useEffect(() => {
    getPetDetailData();
  }, []);

  const getPetDetailData = () => {
    let payload = {
      "date": date,
      "petType": petType
    }
    makeRequest(setLoader, getPetDetailRequest, payload, onPetDataSuccess, onPetDataError);
  }

  const onPetDataSuccess = (data) => {
    setDataSource(data.booked_pets);
    setBookedKennels(data.booked_kannels);
    setTotalRecords(data.booked_pets.length)
    setAvailableKennels(data.available_kannels);
  }

  const onPetDataError = (error) => {
    notify('error', error.message);
  }

  const handleTableChange = (page, fil, sorter) => {
    const current = page.current;
    const size = page.pageSize;
    const start = (current - 1) * size + 1;
    const end = Math.min(start + size - 1, totalRecords);
    setStartRecord(start);
    setEndRecord(end);
    let payload = {
      ...pagination,
      current: current,
      pageSize: size,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  const footer = () => {
    const from = startRecord;
    const to = endRecord;
    return (
      <>
        Showing { from } to { to } of { totalRecords } entries
      </>
    )
  }


  return (
    <>
      <style>{ `.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {display:none;}` }</style>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <Row gutter={ 16 }>
        <Col span={ 12 } className="da-text-right">
          <h4>Booked Kennels : { bookedKennels }</h4>
        </Col>
        <Col span={ 12 } className="da-text-left">
          <h4>Available Kennels : { availableKennels }</h4>
        </Col>
      </Row>
      <BodyComponent>
        <Select
          placeholder="Page Size"
          options={ [
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: 'All', value: totalRecords },
          ] } style={ { width: 120, marginBottom: '10px' } }
          onChange={ (value) => setPageSize(value) }
        />
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords, pageSize: pageSize } } footer={ footer } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );

}




export default PetDetailIndex;