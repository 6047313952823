import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { makeRequest, notify, makeRequestStateless } from '@utils/helpers';
import { getPetReport, getAllClientsRequest } from './requests';
import { HeaderComponent, TableComponent, ButtonComponent } from "@comps/components";
import { Row, Col, Select, Input, Tooltip } from "antd";
import DatePicker from "react-multi-date-picker"
import { Icon } from '@iconify/react';
import { useHistory } from "react-router-dom";

const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Pet Report",
    breadcrumb: [
      {
        name: "Reports",
        path: "/report-management/pets"
      }
    ]
  }
}

const PetReport = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [clients, setClients] = useState([]);
  const [loader, setLoader] = useState(false);
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });


  useEffect(() => {
    getPetReportData();
    getAllClients();
  }, []);

  const getPetReportData = () => {
    makeRequest(setLoader, getPetReport, null, onDataSuccess, onDataError);
  };

  const onDataSuccess = (data) => {
    setData(data);
    setOrgData(data);
  };

  const onDataError = (error) => {
    notify('error', error);
  };

  const columns = [
    {
      title: 'Ref',
      dataIndex: 'id',
    },
    {
      title: 'A/D',
      dataIndex: 'A/D',
    },
    {
      title: 'Pet Name',
      dataIndex: 'pet_name',
    },
    {
      title: 'Pet Type',
      dataIndex: 'pet_type',
    },
    {
      title: 'Customer',
      dataIndex: 'client_name',
    },
    {
      title: 'In',
      dataIndex: 'check_in',
      render: (record) => record ? moment(record).format('DD/MM/YYYY') : null,
    },
    {
      title: 'Out',
      dataIndex: 'check_out',
      render: (record) => record ? moment(record).format('DD/MM/YYYY') : null,
    },
    {
      title: 'Depart',
      dataIndex: 'depart',
      render: (record) => record ? moment(record).format('DD/MM/YYYY') : null,
    },
    {
      title: 'Deposit Paid',
      dataIndex: 'deposit',
    },
    {
      title: 'Action',
      render: (record) => <Tooltip title="View Pet" >
        <ButtonComponent type="link" className="da-ml-8 da-text-light" style={ { color: crmStyle.bg_color, fontSize: 20 } } size="small" onClick={ () => history.push(`/pet-management/pets/view/${record.pet_id}`) }><Icon icon="ic:outline-remove-red-eye" /></ButtonComponent></Tooltip>,
    },
  ];


  const handleExport = () => {
    const csvData = [];
    const headers = columns.map(col => col.title);
    csvData.push(headers);

    data.forEach(record => {
      const row = columns.map(col => record[col.dataIndex]);
      csvData.push(row);
    });

    const csvContent = 'data:text/csv;charset=utf-8,' + csvData.map(row => row.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  const getAllClients = () => {
    makeRequestStateless(getAllClientsRequest, null, onGetAllClientsSuccess, Function);
  }


  const onGetAllClientsSuccess = (response) => {
    setClients(response);
  }

  //filters
  const onPaymentChange = (value) => {
    if (value === 0) {
      //check in 7 days
      const filterData = orgData.filter((item) => {
        const checkInDate = moment(item.check_in);
        const today = moment();
        const daysDiff = today.diff(checkInDate, 'days');
        return daysDiff <= 7;
      });
      setData(filterData);
    } else if (value === 1) {
      const filterData = orgData.filter((item) => {
        const checkInDate = moment(item.check_in);
        const today = moment();
        const daysDiff = today.diff(checkInDate, 'days');
        return daysDiff <= 7 && item.deposit === 'N';
      });
      setData(filterData);
    } else {
      setData(orgData);
    }
  }

  const onDateChange = (value) => {
    let selectDate = moment.unix(value.unix).format('YYYY-MM-DD');
    const filterData = orgData.filter((item) => {
      const checkInDate = moment(item.check_in).format('YYYY-MM-DD');
      return checkInDate === selectDate;
    });
    setData(filterData);
  }


  const onClientChange = (value) => {
    let clientName = clients.find((item) => item.value === value).label.split(' - ')[0];
    const filterData = orgData.filter((item) => {
      return item.client_name === clientName;
    });
    setData(filterData);
  }

  const onMonthChange = (value) => {
    if (value.unix) {
      let selectMonth = moment.unix(value.unix).format('YYYY-MM');
      const filterData = orgData.filter((item) => {
        const checkInDate = moment(item.check_in).format('YYYY-MM');
        return checkInDate === selectMonth;
      });
      setData(filterData);
    }
  }

  const onYearChange = (value) => {
    let selectMonth = moment.unix(value.unix).format('YYYY');
    const filterData = orgData.filter((item) => {
      const checkInDate = moment(item.check_in).format('YYYY');
      return checkInDate === selectMonth;
    });
    setData(filterData);
  }

  const onDepositChange = (value) => {
    const filterData = orgData.filter((item) => {
      return item.deposit === value;
    });
    setData(filterData);
  }


  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
        <Button style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } className='da-mb-16' type="primary" icon={ <ExportOutlined /> } onClick={ handleExport }> Excel</Button>
      </HeaderComponent>
      <Row gutter={ [16, 16] } className="da-mb-16 da-mt-16">
        <Col xs={ 24 } lg={ 6 }>
          <strong><label>Payment</label></strong>
          <Select
            onChange={ (value) => onPaymentChange(value) }
            style={ { width: '100%' } }
            showSearch
            filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
            placeholder="All Report"
            options={ [{ "value": 0, "label": "Booking Due in 7 days with payment" }, { "value": 1, "label": "Booking Due in 7 days with wihtout Payment" }] }
          />
        </Col>
        <Col xs={ 24 } lg={ 6 }>
          <strong><label>Date</label></strong>
          <DatePicker
            onChange={ onDateChange }
            format={ "DD/MM/YYYY" }
            placeholder="Select date"
            containerStyle={ {
              width: "100%"
            } }
            style={ { width: '100%', height: '40px' } }
          />
        </Col>
        <Col xs={ 24 } lg={ 6 }>
          <strong><label>Client</label></strong>
          <Select
            onChange={ (value) => onClientChange(value) }
            style={ { width: '100%' } }
            showSearch
            filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
            placeholder="Filter By Client"
            options={ clients }
          />
        </Col>
        <Col xs={ 24 } lg={ 6 }>
          <strong><label>Month</label></strong>
          <DatePicker
            onlyMonthPicker
            onChange={ onMonthChange }
            format={ "MM" }
            placeholder="Select Month"
            containerStyle={ {
              width: "100%"
            } }
            style={ { width: '100%', height: '40px' } }
          />
        </Col>
      </Row>
      <Row gutter={ [16, 16] } className="da-mb-32 ">

        <Col xs={ 24 } lg={ 6 }>
          <strong><label>Year</label></strong>
          <DatePicker
            onlyYearPicker
            onChange={ onYearChange }
            format={ "YYYY" }
            placeholder="Select Year"
            containerStyle={ {
              width: "100%"
            } }
            style={ { width: '100%', height: '40px' } }
          />
        </Col>
        <Col xs={ 24 } lg={ 6 }>
          <strong><label>Deposit</label></strong>
          <Select
            onChange={ (value) => onDepositChange(value) }
            style={ { width: '100%' } }
            showSearch
            filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
            placeholder="Deposit Status"
            options={ [{ "value": "Y", "label": "Paid" }, { "value": "N", "label": "Unpaid" }] }
          />
        </Col>
      </Row>
      <TableComponent loader={ loader } columns={ columns } dataSource={ data } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } exportable={ true } />
    </>
  );
};

export default PetReport;
