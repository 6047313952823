import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip, Button } from "antd";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { getSettings } from './requests';
import { EditOutlined } from '@ant-design/icons';
import EditSmtpSetting from './components/EditSmtpSetting';
import noimage from '@assets/img/noimage.jpg';

const SmtpSettingIndex = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);


  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = () => {
    makeRequest(setLoader, getSettings, null, onDataSuccess, onDataError);
  }

  const onDataSuccess = (res) => {
    setData(res);
  }

  const onDataError = (error) => {
    notify(error);
  }

  const onEdit = () => {
    setChildComponent(<EditSmtpSetting data={ data } onUpdated={ onUpdated } />);
  }

  const onUpdated = (status) => {
    getSettingsData();
    setChildComponent(null);
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      { childComponent }
      <div className="rightBox">
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 12 }><h4>SMTP Settings</h4></Col>
              <Col className="da-text-right" span={ 12 }><Tooltip title="Update Setting" ><Button className="da-px-10 da-my-0" type="link" size="middle" onClick={ onEdit }><EditOutlined className="icon-style da-text-color-warning-1" /></Button></Tooltip></Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>Email sender</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.email_sender }
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>MAIL MAILER</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.MAIL_MAILER }
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>MAIL HOST</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.MAIL_HOST }
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>MAIL PORT</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.MAIL_PORT }
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>MAIL_USERNAME</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.MAIL_USERNAME }
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>MAIL_PASSWORD</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.MAIL_PASSWORD }
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>MAIL_ENCRYPTION</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.MAIL_ENCRYPTION }
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>MAIL_FROM_ADDRESS</Col>
              <Col className="da-text-right" span={ 16 }>
                { data && data.MAIL_FROM_ADDRESS }
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default SmtpSettingIndex;