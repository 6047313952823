import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { updatePetBreed, getPetBreed } from "../requests";
import  TextArea  from 'antd/lib/input/TextArea';


const formName = "updatePetBreed";

const EditPetBreed = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    object: null,
  });


  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);


  const getData = () => {
    makeRequest(setLoader, getPetBreed, props.id, onDataSuccess, onError);
  }

  const onDataSuccess = (res) => {
    setData(res);
  }

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.id = props.id;
    makeRequest(setLoader, updatePetBreed, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("PetBreed", res.msg);
    props.onUpdated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="update_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  if (!data.object) return (<></>);

  return (

    <ModalComponent mainTitle="Edit" subTitle="Pet Breed" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
        initialValues={ data.object }
      >
       <Form.Item  name="name" rules={ rules.name } label="Name" className="da-mb-16"
          { ...getErrorProps(errors['name']) }
        >
          <Input placeholder="Breed Name" />
        </Form.Item>

        <Form.Item name="description" rules={ rules.description } label="Description" className="da-mb-16"
          { ...getErrorProps(errors['description']) }
        >
          <TextArea placeholder="Enter description" />
        </Form.Item>

      </Form>
    </ModalComponent>
  );


}

export default EditPetBreed

const rules = {
  name: [
    { required: true, message: 'Please input your name!', },
  ],
  description: [
  ],

};
