import { get, post, del, put } from "@utils/axios";

const api = "price-management/price-settings";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getPriceDependencies = () => {
  return get(`${api}/dependencies`);
}

export const getPricingSettingRequest = (id) => {
  return get(api);
}

export const getPricingSetting = (id) => {
  return get(`${api}/${id}`);
}

export const createPricingSetting = (payload) => {
  return post(api, payload);
}

export const updatePricingSetting = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const deletePricingSetting = (id) => {
  return del(`${api}/${id}`);
}

