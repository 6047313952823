import React, { useState, useEffect } from "react";
import { Form, Input, Select, Skeleton } from "antd";
import { getUser } from "../requests";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { updateUser } from "../requests";

import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { useSelector } from 'react-redux';

const formName = "editUser";

const EditUser = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(true);
  const [errors, setErrors] = useState([]);

  const [data, setData] = useState({
    object: null,
    dependencies: []
  });



  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getUser, props.id, onDataSuccess, onError);
  }

  const onDataSuccess = (res) => {
    setData(res);
  }

  const onFinish = (payload) => {
    payload.id = props.id;
    payload = { "object": payload }
    makeRequest(setLoader, updateUser, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("User", res.msg);
    props.onUpdated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['password'] = err;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <>
      <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />
      <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
    </>
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------



  return (
    <ModalComponent mainTitle="Edit" subTitle="User" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>
      { !data.object ? <Skeleton /> :
        <Form
          layout="vertical"
          name={ formName }
          labelCol={ { span: 5 } }
          initialValues={ data.object }
          onFinish={ onFinish }
        >
          <Form.Item name="name" rules={ rules.name } label="Name" className="da-mb-16"
            { ...getErrorProps(errors['name']) }
          >
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" className="da-mb-16">
            <Input disabled={ true } />
          </Form.Item>
          <Form.Item name="role_id" label="Select Role" rules={ rules.role_id } className="da-mb-8"
            { ...getErrorProps(errors['role_id']) }
          >
            <Select
              showSearch
              filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
              placeholder="Select a user role"
              options={ data.dependencies.roles }

            />
          </Form.Item>
          <Form.Item name="allow_booking" label="Allow Booking " rules={ rules.allow_booking } className="da-mb-8"
            { ...getErrorProps(errors['allow_booking']) }
          >
            <Select
              showSearch
              filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
              placeholder="Select Booking Status"
              options={ [{ "value": 1, "label": "Yes" }, { "value": 0, "label": "No" }] }
            />
          </Form.Item>
          <Form.Item name="password" rules={ rules.password } label="Password" className="da-mb-16"
            { ...getErrorProps(errors['password']) }
          >
            <Input placeholder="Enter New Password" />
          </Form.Item>
        </Form>
      }
    </ModalComponent>
  );
}

const rules = {
  name: [
    { required: true, message: 'Please input your password!', },
    { min: 3, message: 'Minimum password length is 3', },
    { max: 100, message: 'Maximum password length is 100', },
  ],
  role_id: [
    { required: true, message: 'Please select user role!', },
  ],
  password: [
    { min: 3, message: 'Minimum password length is 3', },
    { max: 100, message: 'Maximum password length is 100', },
  ],
};

export default EditUser;
