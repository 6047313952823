import { get, post, del } from "@utils/axios";

const api = "booking-management/bookings";

export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getBookings = (payload) => {
    return get(api, payload);
}

export const getBooking = (id) => {
    return get(`${api}/${id}`);
}

export const createBooking = (payload) => {
    return post(api, payload);
}


export const deleteBooking = (id) => {
    return del(`${api}/${id}`);
}

export const getBookingDependencies = () => {
    return get(`${api}/dependencies`);
}

export const getPetsByType = (payload) => {
    return get(`${api}/get-pets/` + payload.client + `/` + payload.pet_type);
}

export const getClientPetsByType = (payload) => {
    return get(`${api}/get-client-pets/` + payload.client + `/` + payload.booking_id);
}

export const addNewPetIntoBooking = (payload) => {
    return post(`${api}/add-new-pet`, payload);
}

export const getPriceOfPet = (payload) => {
    return get(`${api}/get-price/` + payload.booking_type_id + `/` + payload.pet_type_id);
}

export const getBookingData = (payload) => {
    return get(`${api}/get-booking-data/` + payload);
}

export const updateBooking = (payload) => {
    return post(`/booking-management/bookings/update-booking`, payload);
}

export const checkoutBookingRequest = (payload) => {
    return get(`${api}/checkout/` + payload);
}


export const getPriceByPets = (payload) => {
    return get(`${api}/get-pet-price`, payload);
}

//Client Payment

export const cheeckClientPaymentRequest = (id) => {
    return get(`/booking-management/client-bookings/check-payment/` + id);
}

export const cheeckClientFieldRequest = () => {
    return get(`/booking-management/client-bookings/check-fields`);
}

export const cheeckClientBookingLimitRequest = (payload) => {
    return get(`/booking-management/client-bookings/check-client-booking-limit`, payload);
}

export const cheeckClientBookingLimitByRangRequest = (payload) => {
    return post(`/booking-management/client-bookings/check-client-booking-limit-by-range`, payload);
}