import { Modal, Typography } from "antd";
import React from "react";


const BaseModal = (props) => {

  let title = <Typography.Text>{ props.mainTitle }</Typography.Text>
  if (props.subTitle) {
    title = (
      <>
        { title }
        <Typography.Text type="secondary"> | { props.subTitle }</Typography.Text>
      </>
    );
  }
  return <Modal maskClosable={ false } width={ props.width } title={ title } { ...props } bodyStyle={ { padding: "20px", overflowY: 'scroll' } } >{ props.children }</Modal>;
};

export default BaseModal;
