import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { makeRequest } from "@utils/helpers";
import { getUser } from "../requests";
import { CancelButton, ModalComponent } from "@comps/components";


const ViewUser = (props) => {
  const [loader, setLoader] = useState(true);

  const [data, setData] = useState({
    object: null,
    dependencies: []
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getUser, props.id, onSuccess, onError);
  }

  const onSuccess = (res) => {
    setData(res);
  }

  const onError = (res) => {
    // handle call failed error
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  if (!data.object) {
    return "";
  }
  return (
    <ModalComponent loader={ loader } mainTitle="View" subTitle="User" visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>
      <Form
        layout="horizontal"
        labelCol={ { span: 5 } }
        initialValues={ data.object }
      >
        <Form.Item name="name" label="Name :" className="da-mb-16">
          <Input disabled={ true } />
        </Form.Item>
        <Form.Item name="email" label="Email :" className="da-mb-16">
          <Input disabled={ true } />
        </Form.Item>
        <Form.Item name="role_id" label="Select Role :" className="da-mb-8">
          <Select
            showSearch
            filterOption={(d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0}
            placeholder="Select a user role"
            options={ data.dependencies.roles }
            disabled={ true }
          />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
}

export default ViewUser;
