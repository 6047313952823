import { get, post, del, put, postFd } from "@utils/axios";

const api = "pets-management/pets";

export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getPets = (payload) => {
    return get(`${api}/client/`+payload);
}

export const deletePet = (id) => {
    return del(`${api}/${id}`);
}

export const getBookings = (payload) => {
    return get(`/booking-management/bookings/client/`+payload);
}

export const deleteBooking = (id) => {
    return del(`/booking-management/bookings/${id}`);
}

