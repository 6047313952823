import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip, Button } from "antd";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { getSettings } from './requests';
import { EditOutlined } from '@ant-design/icons';
import EditCrmSettings from './components/EditCrmSettings';
import noimage from '@assets/img/noimage.jpg';

const IndexCrmSettings = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);


  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = () => {
    makeRequest(setLoader, getSettings, null, onDataSuccess, onDataError);
  }

  const onDataSuccess = (res) => {
    setData(res);
  }

  const onDataError = (error) => {
    notify(error);
  }

  const onEdit = () => {
    setChildComponent(<EditCrmSettings data={ data } onUpdated={ onUpdated } />);
  }

  const onUpdated = (status) => {
    if (status) {
      getSettingsData();
    }
    setChildComponent(null);
    window.location.reload();
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      { childComponent }
      <div className="rightBox">
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 12 }><h4>CRM Settings</h4></Col>
              <Col className="da-text-right" span={ 12 }><Tooltip title="Update Setting" ><Button className="da-px-10 da-my-0" type="link" size="middle" onClick={ onEdit }><EditOutlined className="icon-style da-text-color-warning-1" /></Button></Tooltip></Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Title</Col>
              <Col className="da-text-right" span={ 12 }>{ data.title }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Site Logo</Col>
              <Col className="da-text-right" span={ 12 }>
                <img className="avatar" src={ data.site_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + data.site_logo : noimage } alt="client" />
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Small Logo/Favicon</Col>
              <Col className="da-text-right" span={ 12 }>
                <img className="avatar" src={ data.email_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + data.email_logo : noimage } alt="client" />
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Login Background</Col>
              <Col className="da-text-right" span={ 12 }>
                <img className="avatar" src={ data.login_background ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + data.login_background : noimage } alt="client" />
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Login Logo</Col>
              <Col className="da-text-right" span={ 12 }>
                <img className="avatar" src={ data.letter_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + data.letter_logo : noimage } alt="client" />
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Default From Email</Col>
              <Col className="da-text-right" span={ 12 }>{ data.default_from_email }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Default To Email</Col>
              <Col className="da-text-right" span={ 12 }>{ data.default_to_email }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Telephone</Col>
              <Col className="da-text-right" span={ 12 }>{ data.telephone }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Address</Col>
              <Col className="da-text-right" span={ 12 }>{ data.address }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Footer Text</Col>
              <Col className="da-text-right" span={ 12 }>{ data.footer_text }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Login Text</Col>
              <Col className="da-text-right" span={ 12 }>{ data.login_text }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Pet Term Text</Col>
              <Col className="da-text-right" span={ 12 }>{ data.pet_term_text }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>Booking Term Text</Col>
              <Col className="da-text-right" span={ 12 }>{ data.booking_term_text }</Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 12 }>User Status</Col>
              <Col className="da-text-right" span={ 12 }>{ data.user_status === 1 ? "Active" : null } { data.user_status === 0 ? "Inactive" : null } { data.user_status === 2 ? "Active No Booking" : null }</Col>
            </Row>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default IndexCrmSettings;