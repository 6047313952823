import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HeaderComponent, BodyComponent, TableComponent, ActionComponent, CreateButton } from "@comps/components";
import { makeRequest, notify, removeById, makeRequestStateless } from "@utils/helpers";
import { getPets, deletePet, getAllClientsRequest } from "./requests";
import { debounce } from 'lodash';
import { Row, Col, Select, Input } from "antd";
import { useSelector } from 'react-redux';
const moment = require('moment');
const loginUser = JSON.parse(localStorage.getItem("user"));

const pageConfig = {
  headers: {
    title: "Pets",
    breadcrumb: [
      {
        name: "Pets",
        path: "/pet-management/pets"
      }
    ]
  }
}

const IndexPet = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const history = useHistory();
  const [clients, setClients] = useState(null);
  const [loader, setLoader] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc',
    filters
  });

  const [childComponent, setChildComponent] = useState(null);

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'name',
      title: 'Pet Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'owner_name',
      title: 'Pet Owner',
      dataIndex: 'owner_name',
      sorter: false,
    },
    {
      key: 'species',
      title: 'Species',
      dataIndex: 'species',
      sorter: false,
    },
    {
      key: 'breed',
      title: 'Breed',
      dataIndex: 'breed',
      sorter: false,
    },
    {
      key: 'age',
      title: 'Age',
      render: (record) => record.dob ? moment().diff(moment(record.dob), 'years') : 0,
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => ActionComponentEx(record)
    },
  ];

  const ActionComponentEx = (record) => {
    return (
      <ActionComponent each={ record } onView={ onView } onEdit={ onEdit } onDelete={ onDelete }>
      </ActionComponent>
    );
  }

  useEffect(() => {
    if (!filters) {
      getAllPets();
    }
    if (loginUser && loginUser.role_id === 1 || loginUser.role_id === 3) {
      getAllClients();
    }
    // eslint-disable-next-line
  }, [pagination, filters]);

  const getAllPets = () => {
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters
    };
    makeRequest(setLoader, getPets, payload, onSuccess, null);
  }


  const onSuccess = (response) => {
    setTotalRecords(response.recordsTotal);
    setDataSource(response.data);
  }


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  // Create component modal
  const onCreate = () => {
    history.push(`/pet-management/pets/create`);
  }

  const onView = (record) => {
    history.push(`/pet-management/pets/view/${record.id}`);
  }

  const onEdit = (record) => {
    history.push(`/pet-management/pets/edit/${record.id}`);
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deletePet, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify(msg.msg)
  }

  const onError = (error, msg) => {
    //
  }

  //Filters

  const getAllClients = () => {
    makeRequestStateless(getAllClientsRequest, null, onGetAllClientsSuccess, Function);
  }


  const onGetAllClientsSuccess = (response) => {
    setClients(response);
  }

  const PetByClient = (value) => {
    setFilters({ client_id: value })
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: 200,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters: {
        client_id: value
      }
    };
    makeRequest(setLoader, getPets, payload, onFilterSuccess, null);
  }

  const getPetByName = (petName) => {
    setFilters({ name: petName })
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: 200,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters: {
        name: petName
      }
    };
    makeRequest(setLoader, getPets, payload, onFilterSuccess, null);
  }

  const onFilterSuccess = (response) => {
    setTotalRecords(response.data.length);
    setDataSource(response.data);
  }

  const debouncedGetPetByName = debounce((e) => {
    getPetByName(e.target.value);
  }, 300);


  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
        <CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } />
      </HeaderComponent>
      <BodyComponent>
        <Row gutter={ [16, 16] } className="da-mb-32 da-mt-32">
          <Col xs={ 24 } lg={ 6 }>
            <Input onChange={ debouncedGetPetByName } placeholder="Enter pet name" />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            { loginUser && loginUser.role_id === 1 || loginUser.role_id === 3 ?
              <Select
                style={ { width: '100%' } }
                showSearch
                onChange={ PetByClient }
                filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                placeholder="Pets By Client"
                options={ clients }
              />
              : null }
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}

export default IndexPet;
