import React, { useState } from "react";
import { Form, Input, Row, Col, Upload } from "antd";
import { useHistory } from "react-router-dom";

import { SaveButton, BodyComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { createClient, deleteImage } from "../requests";
import { HeaderComponent } from '../../../wrappers/components';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const pageConfig = {
  headers: {
    title: "Create Client",
    breadcrumb: [
      {
        name: "Clients",
        path: "/client-management/clients"
      },
      {
        name: "Create Client",
        path: "/client-management/clients/create"
      }
    ]
  }
}
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const formName = "createClient";

const CreateClient = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const history = useHistory();

  // Image upload Start
  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      if (fileList.length > 0) {
        window.open(process.env.REACT_APP_API_BASE_URL.slice(0, -4) + fileList[0].response.url, "_blank")
      }
    }
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={ {
          marginTop: 8,
          width: '200px',
        } }
      >
        Upload
      </div>
    </div>
  );
  // Image upload End

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object.is_active = true;
    payload.object.is_customer = true;
    payload.object.role_id = 2;
    if (fileList.length > 0) {
      payload.object.image = fileList[0].response.url;
    }
    makeRequest(setLoader, createClient, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Client", res.msg);
    history.push(`/client-management/clients`);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    errorList['email'] = err.email;
    setErrors(errorList);
  }

  const onRemoveImage = (file) => {
    makeRequest(setLoader, deleteImage, file.response.id, onSuccess, onError);
  }

  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-p-32">
          <Form
            layout="vertical"
            name={ formName }
            onFinish={ onFinish }
          >
            <h5 className="da-mb-24 color-lightgrey">Account Detail</h5>
            <Row gutter={ 16 }>
              <Col span={ 18 }>
                <Form.Item name="name" rules={ rules.name } label="Full Name" className="da-mb-16"
                  { ...getErrorProps(errors['name']) }
                >
                  <Input placeholder="Enter client name" />
                </Form.Item>

                <Form.Item name="email" rules={ rules.email } label="Email" className="da-mb-16"
                  { ...getErrorProps(errors['email']) }
                >
                  <Input placeholder="Enter client email" />
                </Form.Item>

                <Form.Item name="password" rules={ rules.password } label="Password" className="da-mb-16"
                  { ...getErrorProps(errors['password']) }
                >
                  <Input placeholder="Enter client password" />
                </Form.Item>
              </Col>
              <Col span={ 6 }>
                <label>Image</label>
                <Upload
                  action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
                  listType="picture-card"
                  fileList={ fileList }
                  onPreview={ handlePreview }
                  onChange={ handleChange }
                  onRemove={ onRemoveImage }
                >
                  { fileList.length === 1 ? null : uploadButton }
                </Upload>
              </Col>
            </Row>
            <h5 className="da-mb-24 color-lightgrey">User Detail</h5>
            <Row gutter={ 16 }>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item rules={ rules.phone } { ...getErrorProps(errors['phone']) } name="phone" label="Telephone Number" className="da-mb-16">
                  <Input placeholder="Enter Telephone Number" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item rules={ rules.mobile } { ...getErrorProps(errors['mobile']) } name="mobile" label="Mobile Number" className="da-mb-16">
                  <Input placeholder="Enter Mobile Number" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item rules={ rules.address_line1 } { ...getErrorProps(errors['address_line1']) } name="address_line1" label="Address line 1" className="da-mb-16">
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item rules={ rules.address_line2 } { ...getErrorProps(errors['address_line2']) } name="address_line2" label="Address line 2" className="da-mb-16">
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item name="city" label="City" className="da-mb-16">
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item name="town" label="Town" className="da-mb-16">
                  <Input placeholder="Enter Town" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item name="postal_code" label="Postal Code" className="da-mb-16">
                  <Input placeholder="Enter Postal Code" />
                </Form.Item>
              </Col>
            </Row>

            <h5 className="da-mb-24 color-lightgrey">Other Detail</h5>
            <Row gutter={ 16 }>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item name="partner_name" label="Emergency Name" className="da-mb-16">
                  <Input placeholder="Enter partner name" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item name="partner_ice_phone" label="ICE Telephone" className="da-mb-16">
                  <Input placeholder="Enter ICE Name / Telephone" />
                </Form.Item>
              </Col>
              <Col xl={ 8 } xs={ 24 }>
                <Form.Item name="partner_ice_email" label="ICE Email" className="da-mb-16">
                  <Input placeholder="Enter Emergency Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={ 16 }>
              <Col span={ 24 } className="da-text-right da-pt-32">
                <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } text="Create Client " form={ formName } key="create_button" htmlType="submit" state={ loader } />
              </Col>
            </Row>
          </Form>
        </div>
      </BodyComponent>
    </>
  );
}

export default CreateClient

const rules = {
  name: [
    { required: true, message: 'Please enter client name', },
  ],
  email: [
    { type: "email", message: "The input is not valid email" },
    { required: true, message: "Please input client email" },
  ],
  password: [
    { required: true, message: 'Please enter password', },
  ],
  phone: [
    { required: true, message: 'Please enter phone', },
  ],
  mobile: [
    { required: true, message: 'Please enter mobile', },
  ],
  address_line1: [
    { required: true, message: 'Please enter address', },
  ],
  address_line2: [
    { required: true, message: 'Please enter address', },
  ],
};
