import { get, post, del, put } from "@utils/axios";

const api = "pets-management/pet-medicals";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getPetMedicalRequest = (payload) => {
  return get(api, payload);
}

export const getPetMedical = (id) => {
  return get(`${api}/${id}`);
}

export const createPetMedical = (payload) => {
  return post(api, payload);
}

export const updatePetMedical = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const deletePetMedical = (id) => {
  return del(`${api}/${id}`);
}

export const deleteImage = (id) => {
  return get(`delete-image/` + id);
}

