import { get } from "@utils/axios";

export const getDashboardData = (date) => {
  return get(`/configs-management/get-dashboard-data/${date}`);
}

export const getPetDetailRequest = (payload) => {
  return get(`/configs-management/get-petdetail-data/${payload.date}/${payload.petType}`);
}

export const getBookingPetCheckinRequest = (id) => {
  return get(`booking-management/bookings/pets/checkin/` + id);
}

export const getBookingPetCheckoutRequest = (id) => {
  return get(`booking-management/bookings/pets/checkout/` + id);
}

//Client Dashboard
export const getClientDashboardData = (date) => {
  return get(`/clients-management/clients/get/dashboard-data/${date}`);
}
