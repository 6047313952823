import { get, post, del, put } from "@utils/axios";

const api = "booking-management/bookings";

export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getBookingPetRequest = (payload) => {
    return get(`${api}/pets/` + payload);
}

export const getBookingPetCheckinRequest = (payload) => {
    return get(`${api}/pets/checkin/` + payload);
}

export const getBookingPetCheckoutRequest = (payload) => {
    return get(`${api}/pets/checkout/` + payload);
}

export const deleteBookingPet = (payload) => {
    return get(`${api}/pets/delete/${payload.booking_id}/${payload.id}`);
}
