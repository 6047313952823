import { get, post, del, put } from "@utils/axios";

const api = "configs-management/allow-days";

export const getFilters = () => {
  return get(`${api}/filters`);
}


export const getAllowDay = (id) => {
  return get(`${api}/${id}`);
}

export const updateAllowDays = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const updateDisableDates = (payload) => {
  return post(`/configs-management/disable-dates`, payload);
}

export const getDisableDatesRequest = () => {
  return get(`/configs-management/disable-dates`);
}
