import { BodyComponent, ButtonComponent } from "@comps/components";
import { Row, Col, Card, Badge, Calendar, Popconfirm } from 'antd';
import { ClockCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getClientDashboardData, getDashboardData } from './requests';
import { makeRequest, notify } from '@utils/helpers';
const moment = require('moment');

const ClientDashboard = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [petTypes, setPetTypes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let date = moment().format('YYYY-MM');
    makeRequest(setLoader, getClientDashboardData, date, onDatSuccess, onDatError);
  }

  const onDatSuccess = (data, res) => {
    setData(data);
    setPetTypes(data.petTypes);
  }

  const onDatError = (error) => {
    console.log(error);
  }

  const gotoAttendance = () => {
    history.push('/booking-management/bookings');
  }

  const handlePanelChange = (value, mode) => {
    makeRequest(setLoader, getClientDashboardData, value.format('YYYY-MM'), onDatSuccess, onDatError);
    // Call your function here with the new month or year
  };

  if (!data) {
    return null;
  }

  const dateCellRender = (value) => {
    let date = moment(value).format('YYYY-MM-D');

    return (
      <ul className="events">
        { petTypes.map((item) => date === item.date ?

          <li key={ item.name }>
            <div onClick={ () => history.push(`/booking-management/bookings`) } style={
              {
                background: item.bg_color,
                color: item.text_color,
                border: item.border_color,
                marginBottom: 5,
                paddingLeft: 10,
              }
            }>{ item.name } { "(" + item.total_booking + ")" }</div>
          </li>
          : null
        ) }
      </ul>
    );
  };

  return (
    <div className="mainbox">
      <style>{ `.events .ant-badge-status {border:none;background:none;}.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child { border-color:${crmStyle.border_color};color:${crmStyle.text_color}}}.ant-radio-button-wrapper-checked{border-right-color:${crmStyle.border_color};}.ant-radio-button-wrapper {color:${crmStyle.text_color}}.ant-radio-button-wrapper-checked{
        border-color:${crmStyle.border_color} !important;
      }.ant-picker-calendar-header .ant-picker-calendar-mode-switch {display:none}` }</style>

      <h3>Analytics Overview</h3>
      <BodyComponent>
        <div className="da-mt-24">
          <Row justify="center" gutter={ 16 } >
            <Col onClick={ gotoAttendance } xs={ 24 } lg={ { span: 12, offset: 0 } } xl={ { span: 12, offset: 0 } } xxl={ { span: 12, offset: 0 } } className="da-text-center da-mb-12" style={ { padding: 8, cursor: 'pointer' } }>
              <Card style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } bordered={ true }>
                <h4>{ data.total_bookings }</h4>
                My Bookings
              </Card>
            </Col>
            <Col xs={ 24 } lg={ { span: 12, offset: 0 } } xl={ { span: 12, offset: 0 } } xxl={ { span: 12, offset: 0 } } className="da-text-center da-mb-12" style={ { padding: 8 } }>
              <Card style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } bordered={ true }>
                <h4>{ data.total_pets }</h4>
                My Pets
              </Card>
            </Col>
          </Row>
        </div>

        <div className="da-mt-64">
          <Row justify="center" gutter={ 8 }>
            <Col xs={ 24 } xl={ { span: 12 } } className="da-pr-16 ">
              <div className="da-mb-12  da-h-100 da-pt-24 da-pl-24 da-pb-24 arriveBox">
                <h5>My Bookings</h5>
                <div style={ {
                  height: 300,
                  overflowY: 'scroll',
                } }>
                  { data.bookings && data.bookings.map((item, index) =>
                    <div className="da-mt-12 bookingBox da-p-12 da-text-center da-mr-16 dasboardpet">
                      <Row gutter={ 16 }>
                        <Col span={ 4 }>
                          <ClockCircleOutlined /> { item.start_date }
                        </Col>
                        <Col span={ 4 }>
                          { item ? item.booking_type.name : null }

                        </Col>
                      </Row>
                    </div>
                  ) }
                </div>
              </div>
            </Col>
            <Col xs={ 24 } xl={ { span: 12 } } className="arriveBox da-h-100 da-pt-24 da-pl-24">
              <h5>My Pets</h5>
              <div style={ {
                height: 300,
                overflowY: 'scroll',
              } }>
                { data.pets && data.pets.map((item, index) =>
                  <div className="da-mt-12 bookingBox da-p-12 da-text-center da-mr-16 dasboardpet">
                    <Row gutter={ 16 }>
                      <Col span={ 4 }>
                        <strong>{ item.name }</strong>
                      </Col>
                      <Col span={ 4 }>
                        <strong>{ item.pet_type ? item.pet_type.name : null }</strong>

                      </Col>
                      <Col span={ 8 }>
                        <ButtonComponent className="da-ml-8 da-text-light" style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } size="small" type="primary" onClick={ () => history.push(`/pet-management/pets/edit/${item.id}`) }>Edit</ButtonComponent>
                      </Col>
                    </Row>
                  </div>
                ) }
              </div>
            </Col>
          </Row>
        </div>
        <div className="da-mt-64" style={ { overflow: 'scroll' } } >
          <div className="mobilecalender">
            <Calendar onPanelChange={ handlePanelChange } mode="month" fullscreen={ true } dateCellRender={ dateCellRender } />
          </div>
        </div>

      </BodyComponent>

    </div>
  );
}

export default ClientDashboard;
