import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HeaderComponent, BodyComponent, TableComponent, ActionComponent, CreateButton, FilterComponent } from "@comps/components";
import { makeRequest, notify, removeById, replaceById } from "@utils/helpers";
import { getClients, getFilters, deleteClient } from "./requests";
import { Row, Col, Input } from "antd";
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const pageConfig = {
  headers: {
    title: "Clients",
    breadcrumb: [
      {
        name: "Clients",
        path: "/client-management/clients"
      }
    ]
  }
}

const IndexClient = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const [childComponent, setChildComponent] = useState(null);

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'name',
      title: 'Client Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'nopets',
      title: 'No. of pets',
      dataIndex: 'nopets',
      sorter: true,
    },
    {
      key: 'pets',
      title: 'Pet Name',
      dataIndex: 'pets',
      sorter: true,
    },
    {
      key: 'mobile',
      title: 'Mobile',
      dataIndex: 'clientInfo',
      sorter: false,
      render: (clientInfo) => {
        return clientInfo && clientInfo.id === undefined ? JSON.parse(clientInfo).mobile : clientInfo.mobile;
      }
    },
    {
      key: 'address',
      title: 'Address',
      dataIndex: 'clientInfo',
      sorter: false,
      render: (clientInfo) => {
        return clientInfo && clientInfo.id === undefined ? JSON.parse(clientInfo).address_line1 : clientInfo.address_line1;
      }
    },
    {
      key: 'partner',
      title: 'Partner',
      dataIndex: 'clientInfo',
      sorter: false,
      render: (clientInfo) => {
        return clientInfo && clientInfo.id === undefined ? JSON.parse(clientInfo).partner_name : clientInfo.partner_name;
      }
    },
    {
      key: 'icename',
      title: 'Ice Name / Phone',
      dataIndex: 'clientInfo',
      sorter: false,
      render: (clientInfo) => {
        return clientInfo && clientInfo.id === undefined ? JSON.parse(clientInfo).partner_ice_phone : clientInfo.partner_ice_phone;
      }
    },
    {
      key: 'note',
      title: 'Note',
      dataIndex: 'clientInfo',
      sorter: false,
      render: (clientInfo) => {
        return clientInfo && clientInfo.id === undefined ? <div style={ { width: 100 } }>{ JSON.parse(clientInfo).note }</div> : clientInfo.note;
      }
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => ActionComponentEx(record)
    },
  ];

  const ActionComponentEx = (record) => {
    return (
      <ActionComponent each={ record } onView={ onView } onEdit={ onEdit } onDelete={ onDelete }>
      </ActionComponent>
    );
  }

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, [pagination, filters]);

  const getAllUsers = () => {
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters
    };
    makeRequest(setLoader, getClients, payload, onSuccess, null);
  }


  const onSuccess = (response) => {
    setTotalRecords(response.recordsTotal);
    setDataSource(response.data);
  }


  const handleTableChange = (page, fil, sorter) => {

    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  // Create component modal
  const onCreate = () => {
    history.push(`/client-management/clients/create`);
  }

  const onView = (record) => {
    history.push(`/client-management/clients/view/${record.id}`);
  }

  const onEdit = (record) => {
    history.push(`/client-management/clients/edit/${record.id}`);
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deleteClient, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify(msg.msg)
  }

  const onError = (res, msg) => {
    if (res.code === 403) {
      notify(res.msg, "", "error")
    } else {
      notify(res.msg)
    }
  }

  const getClientByName = (petName) => {
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters: {
        name: petName
      }
    };
    makeRequest(setLoader, getClients, payload, onSuccess, null);
  }

  const debouncedGetClientByName = debounce((e) => {
    getClientByName(e.target.value);
  }, 300);

  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
        <CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } />
      </HeaderComponent>
      <BodyComponent>
        <Row gutter={ [16, 16] } className="da-mb-32 da-mt-32">
          <Col xs={ 24 } lg={ 6 }>
            <Input onChange={ debouncedGetClientByName } placeholder="Search By Name" />
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}

export default IndexClient;
