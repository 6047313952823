import { get, post, del, put } from "@utils/axios";

const api = "pets-management/pet-breeds";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getPetBreedRequest = (id) => {
  return get(api);
}

export const getPetBreed = (id) => {
  return get(`${api}/${id}`);
}

export const createPetBreed = (payload) => {
  return post(api, payload);
}

export const updatePetBreed = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const deletePetBreed = (id) => {
  return del(`${api}/${id}`);
}

