import { ModalComponent } from "@comps/components";
import Iframe from "./Iframe";

const userData = JSON.parse(localStorage.getItem("user"));
const ClientStripePay = (props) => {

  const onPaymentComplete = (data) => {
    props.onCreated(props.payload);
  }

  const footer = [
  ];
  return (
    <ModalComponent width={ 600 } mainTitle="Invoice" subTitle="Deposit Payment" visible={ true } footer={ footer } onCancel={ () => props.onCreated(props.payload) }>
      <Iframe onPaymentComplete={ onPaymentComplete } src={ `${process.env.REACT_APP_API_BASE_URL}/client-stripe?data=${props.data}` } />
    </ModalComponent>
  )
}

export default ClientStripePay