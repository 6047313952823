import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, Checkbox, Upload, message } from "antd";
import { useParams, useHistory } from "react-router-dom";

import { SaveButton, BodyComponent, HeaderComponent } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps, notify } from "@utils/helpers";
import { updateBooking, getBookingData } from "../requests";
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { useSelector } from 'react-redux';
const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Update Booking Detail",
    breadcrumb: [
      {
        name: "Bookings",
        path: "/booking-management/bookings"
      },
      {
        name: "Update Booking",
        path: "/booking-management/bookings"
      }
    ]
  }
}

const formName = "updateBooking";
const EditPet = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const history = useHistory();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [bookingTypes, setBookingTypes] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [multiDates, setMultiDates] = useState([]);
  const [deposit, setDeposit] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getBookingData, id, onDataSuccess, onError);
  }

  const onDataSuccess = (data, res) => {
    setBookingTypes(data.bookingType);
    setStartDate(new Date(data.start_date));
    setEndDate(new Date(data.end_date));
    if (data.dates) {
      setMultiDates(data.dates.map((date) => new Date(date.booking_date)));
    }
    setDeposit(data.deposit);
  }


  const onFinish = () => {
    let dates = multiDates.map((date) => date.unix ? moment.unix(date.unix).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD'));
    let start_date = null;
    let end_date = null;
    if (dates.length === 0) {
      let currentDate = startDate.unix ? moment.unix(startDate.unix).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD');
      let cendDate = endDate.unix ? moment.unix(endDate.unix).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD');
      while (currentDate <= cendDate) {
        dates.push(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
      }
      end_date = cendDate;
      dates.sort(function (a, b) {
        a = a.split('/').reverse().join('');
        b = b.split('/').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0;
      });
      start_date = dates[0];
    }
    let payload = {
      id: id,
      is_range: bookingTypes.is_range,
      booking_type_id: bookingTypes.id,
      dates: dates,
      start_date: start_date,
      end_date: end_date,
      deposit: deposit
    }

    makeRequest(setLoader, updateBooking, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Booking", res.msg);
    history.push(`/booking-management/bookings`);
  }

  const onError = (err) => {
    message.error(err.msg);
  }



  if (bookingTypes == null) {
    return null;
  }

  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-p-32">

          <h5 className="da-mb-24 color-lightgrey">Booking Types </h5>
          <Row gutter={ 16 }>
            <Col span={ 14 }>
              <Input value={ bookingTypes.name } disabled={ true } />
            </Col>
          </Row>
          <h5 className="da-mb-24 da-mt-24 color-lightgrey">Booking Details</h5>
          { bookingTypes != null ?
            <>
              { bookingTypes.is_range ?
                <Row gutter={ 16 }>
                  <Col span={ 7 }>
                    <DatePicker
                      format={ "DD/MM/YYYY" }
                      value={ startDate }
                      onChange={ (value) => setStartDate(value) }
                      placeholder="Select a start date"
                      containerStyle={ {
                        width: "100%"
                      } }
                      style={ { width: '100%', height: '40px' } }
                    />
                  </Col>
                  <Col span={ 7 }>
                    <DatePicker
                      value={ endDate }
                      onChange={ (value) => setEndDate(value) }
                      format={ "DD/MM/YYYY" }
                      // minDate={ moment().toDate() }
                      placeholder="Select a end date"
                      containerStyle={ {
                        width: "100%"
                      } }
                      style={ { width: '100%', height: '40px' } }
                    />
                  </Col>
                </Row>
                :
                <Row gutter={ 16 }>
                  <Col span={ 14 }>

                    <DatePicker
                      // minDate={ moment().toDate() }
                      value={ multiDates }
                      onChange={ (value) => setMultiDates(value) }
                      format={ "DD/MM/YYYY" }
                      placeholder="Select a date"
                      containerStyle={ {
                        width: "100%"
                      } }
                      style={ { width: '100%', height: '40px' } }
                      multiple
                      plugins={ [
                        <DatePanel />
                      ] }
                    />
                    <small>You can select multiple dates</small>
                  </Col>
                </Row>
              }
            </>
            : null
          }

          <Row gutter={ 16 } className="da-mt-32">
            <Col span={ 14 }>
              <label for="">Deposit</label>
              <Select
                disabled={ deposit }
                className="da-w-100"
                showSearch
                onChange={ (value) => setDeposit(value) }
                value={ deposit }
                filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                placeholder="Select a deposit status"
                options={ [{ "value": 0, "label": "Not Paid" }, { "value": 1, "label": "Deposit Paid" }] }
              />
            </Col>
          </Row>

          <Row>
            <Col span={ 24 } className="da-text-right da-mt-24">
              <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onFinish } text="Update Booking " state={ loader } />
            </Col>
          </Row>
        </div>
      </BodyComponent>
    </>
  );
}

export default EditPet
