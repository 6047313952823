import { memo } from "react";
import { useEffect } from "react";
import { useSelector } from 'react-redux'
import MainApp from "./Main";
import AuthApp from "@mods/userManagement/auth/routes";

const favicon = document.querySelector('[rel=icon]');
const webData = localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {};

const App = () => {

  // // Set the desired timezone
  // const timeZone = 'Europe/London'; // Replace with the desired timezone
  // moment.tz.setDefault(timeZone);

  useEffect(() => {
    document.title = webData ? webData.title ? webData.title : 'loading' : 'Loading';
    favicon.href = webData ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webData.email_logo : '';
  }, []);

  const authUser = useSelector((state) => state.auth.authUser);

  return (
    authUser && authUser.id ? <MainApp /> : <AuthApp />
  );

}

export default memo(App);
