import { get, post } from "@utils/axios";

export const getSettings = () => {
  return get(`configs-management/get-settings`);
}

export const getInvoice = (number) => {
  return get(`/invoice-management/get-invoice/` + number);
}

export const createInvoiceAddonReq = (data) => {
  return post(`/invoice-management/add-invoice-addons`, data);
}

export const updateInvoiceAddonReq = (data) => {
  return post(`/invoice-management/update-invoice-addons`, data);
}

export const deleteInvoiceAddonReq = (id) => {
  return get(`/invoice-management/delete-invoice-addons/` + id);
}
