import React, { useState, useEffect } from "react";
import { Button, Tag, Tooltip, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { HeaderComponent, BodyComponent, TableComponent, ActionComponent, CreateButton, FilterComponent } from "@comps/components";
import { makeRequest, formatCompleteDataTime, notify, removeById, replaceById } from "@utils/helpers";
import { getUsers, activateUserRequest, getFilters, deleteUser } from "./requests";
import CreateUser from "./components/CreateUser";
import ViewUser from './components/ViewUser';
import EditUser from './components/EditUser';
import { useSelector } from 'react-redux';

const pageConfig = {
  headers: {
    title: "Users",
    breadcrumb: [
      {
        name: "Users",
        path: "/user-management/users"
      }
    ]
  }
}

const IndexUser = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const [childComponent, setChildComponent] = useState(null);

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },
    {
      key: 'role_name',
      title: 'Role',
      dataIndex: 'role_name',
      sorter: false,
    },
    {
      key: 'allow_booking',
      title: 'Allow Booking',
      dataIndex: 'allow_booking',
      sorter: false,
      render: (allow_booking) => allow_booking ? "Yes" : "No"
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'is_active',
      sorter: true,
      render: (is_active) => {
        let color = is_active ? 'green' : 'red';
        let text = is_active ? 'ACTIVE' : 'INACTIVE';
        return (
          <Tag color={ color } >{ text }</Tag>
        );
      }
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => ActionComponentEx(record)
    },
  ];

  const ActionComponentEx = (record) => {
    let icon = null;
    if (record) {
      if (record.is_active) {
        icon = <Tooltip title="Deactivate User" color='yellow' > <CloseOutlined className="icon-style da-text-color-danger-1" /></Tooltip>;
      } else {
        icon = <Tooltip title="Activate User" color='yellow' ><CheckOutlined className="icon-style da-text-color-success-1" /></Tooltip>;
      }
    }
    return (
      record.id === 1 ?
        <ActionComponent each={ record } onView={ onView } >
        </ActionComponent>
        :
        <ActionComponent each={ record } onView={ onView } onEdit={ onEdit } onDelete={ onDelete }>
          <Button className="da-px-10 da-my-0" type="link" size="middle" onClick={ () => activateDeactiveUser(record) }>{ icon }</Button>
        </ActionComponent>
    );
  }

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, [pagination, filters]);

  const getAllUsers = () => {
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters
    };
    makeRequest(setLoader, getUsers, payload, onSuccess, onError);
  }

  const activateDeactiveUser = (user) => {
    makeRequest(setLoader, activateUserRequest, user, onActivateSuccess, onError);
  }
  const onActivateSuccess = (res, msg) => {
    setDataSource(replaceById(dataSource, res));
    notify(msg.msg)
    getAllUsers();
  }
  const onSuccess = (response) => {
    setTotalRecords(response.recordsTotal);
    setDataSource(response.data);
  }

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateUser onCreated={ onCreated } />);
  }

  const onCreated = (each) => {
    if (each) {
      setDataSource([...dataSource, each.object]);
    }
    setChildComponent(null);
  }

  const onUpdated = (each) => {
    if (each) {
      setDataSource(replaceById(dataSource, each.object));
    }
    setChildComponent(null);
  }



  const onView = (record) => {
    setChildComponent(<ViewUser id={ record.id } onCreated={ onCreated } />);
  }

  const onEdit = (record) => {
    setChildComponent(<EditUser id={ record.id } onUpdated={ onUpdated } />);
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deleteUser, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify(msg.msg)
  }

  const onError = (res, msg) => {
    if (res.code === 403) {
      notify(res.msg, "", "error")
    }
  }

  if (dataSource.length === 0 && totalRecords === 0) {
    return null;
  }

  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
        <CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } />
      </HeaderComponent>
      <BodyComponent>
        <FilterComponent filters={ availableFilters } onFilter={ setFilters } api={ getFilters } />
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}

export default IndexUser;

const availableFilters = [
  {
    key: 'name',
    placeholder: 'User Name',
    type: 'text',
  },
  {
    key: 'email',
    placeholder: 'User Email',
    type: 'text',
  },
  {
    key: 'is_active',
    placeholder: 'Select Status',
    type: 'select',
    data_key: 'actives'
  },
  {
    key: 'role_id',
    placeholder: 'Select Role',
    type: 'select',
    data_key: 'all_roles',
  }
];
