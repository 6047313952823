import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Upload, Select } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { deleteImage, updateSettings } from "../requests";


const formName = "updatePetType";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditCrmSettings = (props) => {
  const { TextArea } = Input;
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  // Image upload Start

  const [siteLogo, setSiteLogo] = useState([]);
  const [loginBg, setLoginBg] = useState([]);
  const [letterLogo, setLetterLogo] = useState([]);
  const [emailLogo, setEmailLogo] = useState([]);

  const handlePreview = async (file, image) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      if (image.length > 0) {
        window.open(process.env.REACT_APP_API_BASE_URL.slice(0, -4) + image[0].response.url, "_blank")
      }
    } else {
      window.open(file.preview, "_blank")
    }
  };

  const handleSiteLogoChange = ({ fileList: newFileList }) => setSiteLogo(newFileList);
  const handleBgChange = ({ fileList: newFileList }) => setLoginBg(newFileList);
  const handleLetterLogoChange = ({ fileList: newFileList }) => setLetterLogo(newFileList);
  const handleEmailLogoChange = ({ fileList: newFileList }) => setEmailLogo(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={ {
          marginTop: 8,

        } }
      >
        Upload
      </div>
    </div>
  );
  // Image upload End

  useEffect(() => {
    if (props.data) {
      restoreImg(props.data.site_logo_obj, setSiteLogo);
      restoreImg(props.data.login_background_obj, setLoginBg);
      restoreImg(props.data.letter_logo_obj, setLetterLogo);
      restoreImg(props.data.email_logo_obj, setEmailLogo);
    }
  }, [props.data]);

  const restoreImg = (obj, setFileList) => {
    if (obj) {
      setFileList([{ "id": obj.id, "url": process.env.REACT_APP_API_BASE_URL.slice(0, -4) + obj.url, "name": obj.name, "status": "done", "uid": obj.id, path: obj.url }]);
    }
  }

  const onFinish = (values) => {
    let payload = {
      "object": values,
    }
    payload.object.site_logo = handleImg(siteLogo);
    payload.object.login_background = handleImg(loginBg);
    payload.object.letter_logo = handleImg(letterLogo);
    payload.object.email_logo = handleImg(emailLogo);

    makeRequest(setLoader, updateSettings, payload, onUpdatSuccess, onUpdatError);
  }

  const onUpdatSuccess = (data, res) => {
    localStorage.setItem("webData", JSON.stringify(data.object));
    notify("Site Setting", res.msg);
  }

  const onUpdatError = (data) => {
    setErrors(data);
  }

  const handleImg = (file) => {
    if (file.length > 0 && file[0].response) {
      return file[0].response.url;
    } else if (file.length > 0 && file[0].url) {
      return file[0].path
    }
  }

  const onRemoveImage = (file) => {
    makeRequest(setLoader, deleteImage, file.response ? file.response.id : file.id, Function, Function);
  }


  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } form={ formName } key="update_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent width={ 1000 } mainTitle="Edit" subTitle="CRM Settings" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
        initialValues={ props.data }
      >
        <Row gutter={ 16 }>
          <Col span={ 12 }>
            <Form.Item name="title" rules={ rules.title } label="Title" className="da-mb-16"
              { ...getErrorProps(errors['title']) }
            >
              <Input placeholder="Enter title" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="telephone" rules={ rules.telephone } label="Telephone" className="da-mb-16"
              { ...getErrorProps(errors['telephone']) }
            >
              <Input placeholder="Enter telephone" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="address" rules={ rules.address } label="Address" className="da-mb-16"
              { ...getErrorProps(errors['address']) }
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="email" rules={ rules.email } label="Email" className="da-mb-16"
              { ...getErrorProps(errors['email']) }
            >
              <Input placeholder="Enter email" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="default_from_email" rules={ rules.default_from_email } label="Default from email" className="da-mb-16"
              { ...getErrorProps(errors['default_from_email']) }
            >
              <Input placeholder="Enter default from email" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="default_to_email" rules={ rules.default_to_email } label="Default to email" className="da-mb-16"
              { ...getErrorProps(errors['default_to_email']) }
            >
              <Input placeholder="Enter default to email" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="footer_text" rules={ rules.footer_text } label="Footer text" className="da-mb-16"
              { ...getErrorProps(errors['footer_text']) }
            >
              <TextArea rows={ 4 } placeholder="Enter footer text" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="login_text" rules={ rules.login_text } label="Login text" className="da-mb-16"
              { ...getErrorProps(errors['login_text']) }
            >
              <TextArea rows={ 4 } placeholder="Enter login text" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="pet_term_text" rules={ rules.pet_term_text } label="Pet term text" className="da-mb-16"
              { ...getErrorProps(errors['pet_term_text']) }
            >
              <TextArea rows={ 4 } placeholder="Enter pet term text" />
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item name="booking_term_text" rules={ rules.booking_term_text } label="Booking term text" className="da-mb-16"
              { ...getErrorProps(errors['booking_term_text']) }
            >
              <TextArea rows={ 4 } placeholder="Enter booking term text" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="user_status" rules={ rules.user_status } label="Default User Status" className="da-mb-16"
              { ...getErrorProps(errors['user_status']) }
            >
              <Select
                showSearch
                filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                placeholder="Select Status"
                options={ [{ "label": "Active", "value": 1 }, { "label": "Active No Booking", "value": 2 }, { "label": "Inactive", "value": 0 }] }
              />
            </Form.Item>
          </Col>
          <Col lg={ 6 } xl={ 6 } xs={ 12 }>
            <label>Site Logo</label>
            <Upload
              action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
              listType="picture-card"
              fileList={ siteLogo }
              onPreview={ (file) => handlePreview(file, siteLogo) }
              onChange={ handleSiteLogoChange }
              onRemove={ onRemoveImage }
            >
              { siteLogo.length === 1 ? null : uploadButton }
            </Upload>
          </Col>
          <Col lg={ 6 } xl={ 6 } xs={ 12 }>
            <label>Login Background</label>
            <Upload
              action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
              listType="picture-card"
              fileList={ loginBg }
              onPreview={ (file) => handlePreview(file, loginBg) }
              onChange={ handleBgChange }
              onRemove={ onRemoveImage }
            >
              { loginBg.length === 1 ? null : uploadButton }
            </Upload>
          </Col>
          <Col lg={ 6 } xl={ 6 } xs={ 12 }>
            <label>Login logo</label>
            <Upload
              action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
              listType="picture-card"
              fileList={ letterLogo }
              onPreview={ (file) => handlePreview(file, letterLogo) }
              onChange={ handleLetterLogoChange }
              onRemove={ onRemoveImage }
            >
              { letterLogo.length === 1 ? null : uploadButton }
            </Upload>
          </Col>
          <Col lg={ 6 } xl={ 6 } xs={ 12 }>
            <label>Small logo</label>
            <Upload
              action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
              listType="picture-card"
              fileList={ emailLogo }
              onPreview={ (file) => handlePreview(file, emailLogo) }
              onChange={ handleEmailLogoChange }
              onRemove={ onRemoveImage }
            >
              { emailLogo.length === 1 ? null : uploadButton }
            </Upload>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  );


}

export default EditCrmSettings

const rules = {
  title: [
    { required: true, message: 'Please input your title!', },
  ],

};
