import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexClient from "./IndexClient";
import CreateClient from "./components/CreateClient";
import EditClient from "./components/EditClient";
import ViewClient from "./components/ViewClient";
import PermissionDenied from "../dashboard/PermissionDenied";
import Profile from "../dashboard/Profile";


const loginUser = JSON.parse(localStorage.getItem("user"));

const ClientModule = ({ match }) => (
  <Switch>
    <Route exact path={ `${match.url}/clients` } component={ loginUser ? loginUser.role_id === 1 || loginUser.role_id === 3 ? IndexClient : PermissionDenied : PermissionDenied } />
    <Route exact path={ `${match.url}/profile` } component={ Profile } />
    <Route exact path={ `${match.url}/clients/create` } component={ CreateClient } />
    <Route exact path={ `${match.url}/clients/edit/:id` } component={ EditClient } />
    <Route exact path={ `${match.url}/clients/view/:id` } component={ ViewClient } />
  </Switch>
);

export default ClientModule;
