import { get, post, del, put } from "@utils/axios";

const api = "pets-management/pets";

export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getPets = (payload) => {
    return get(api, payload);
}

export const getPet = (id) => {
    return get(`${api}/${id}`);
}

export const createPet = (payload) => {
    return post(api, payload);
}

export const updatePet = (payload) => {
    return put(`${api}/${payload.object.id}`, payload);
}

export const deletePet = (id) => {
    return del(`${api}/${id}`);
}

export const getPetDependencies = () => {
    return get(`${api}/dependencies`);
}

export const deleteImage = (id) => {
    return get(`delete-image/` + id);
}

export const getBookings = (payload) => {
    return get(`/booking-management/bookings/pet/` + payload);
}

export const deleteBooking = (id) => {
    return del(`/booking-management/bookings/${id}`);
}


export const getPetCarePlan = (id) => {
    return get(`${api}/careplan/${id}`);
}


export const getAllClientsRequest = () => {
    return get(`/booking-management/bookings-get-all-clients`);
}
