import { get, post, del, put } from "@utils/axios";

const api = "booking-management/booking-limits";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getDependencies = () => {
  return get(`${api}/dependencies`);
}

export const getBookingLimitRequest = (id) => {
  return get(api);
}

export const getBookingLimitReq = (id) => {
  return get(`${api}/${id}`);
}

export const createBookingLimit = (payload) => {
  return post(api, payload);
}

export const updateBookingLimit = (payload) => {
  return put(`${api}/${payload.object.id}`, payload);
}

export const deleteBookingLimit = (id) => {
  return del(`${api}/${id}`);
}

