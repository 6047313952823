import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col } from "antd";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { updatePetType, getPetType } from "../requests";


const formName = "updatePetType";

const EditPetType = (props) => {

  const [loader, setLoader] = useState(false);
  const [bgDisplayColorPicker, setBgDisplayColorPicker] = useState(false);
  const [borderDisplayColorPicker, setBorderDisplayColorPicker] = useState(false);
  const [textDisplayColorPicker, setTextDisplayColorPicker] = useState(false);
  const [errors, setErrors] = useState([]);
  const [bgColor, setBgColor] = useState("red");
  const [borderColor, setBorderColor] = useState("green");
  const [textColor, setTextColor] = useState("blue");
  const [data, setData] = useState({
    object: null,
  });



  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);


  const getData = () => {
    makeRequest(setLoader, getPetType, props.id, onDataSuccess, onError);
  }

  const onDataSuccess = (res) => {
    setData(res);
    setBgColor(res.object.bg_color);
    setBorderColor(res.object.border_color);
    setTextColor(res.object.text_color);
  }

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object.bg_color = bgColor;
    payload.object.border_color = borderColor;
    payload.object.text_color = textColor;
    payload.id = props.id;
    makeRequest(setLoader, updatePetType, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("PetType", res.msg);
    props.onUpdated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="update_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  const styles = reactCSS({
    'default': {
      bgColor: {
        width: '40px',
        height: '20px',
        borderRadius: '2px',
        background: bgColor,
      },
      borderColor: {
        width: '40px',
        height: '20px',
        borderRadius: '2px',
        background: borderColor,
      },
      textColor: {
        width: '40px',
        height: '20px',
        borderRadius: '2px',
        background: textColor,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'relative',
        zIndex: '999',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  if (!data.object) return (<></>);
  return (

    <ModalComponent mainTitle="Edit" subTitle="Pet Type" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
        initialValues={ data.object }
      >
        <Form.Item name="name" rules={ rules.name } label="Name" className="da-mb-16"
          { ...getErrorProps(errors['name']) }
        >
          <Input placeholder="Type Name" />
        </Form.Item>

        <Form.Item name="booking_limit" rules={ rules.booking_limit } label="Booking Limit" className="da-mb-16"
          { ...getErrorProps(errors['booking_limit']) }
        >
          <Input placeholder="Type booking limit" type="number" />
        </Form.Item>

        <Row gutter={ 16 }>
          <Col span={ 12 }>
            <strong>Background Color</strong>
          </Col>
          <Col span={ 12 } className="da-text-right da-mb-16">
            <div style={ styles.swatch } onClick={ () => setBgDisplayColorPicker(!bgDisplayColorPicker) }>
              <div style={ styles.bgColor } />
            </div>
            { bgDisplayColorPicker ? <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ () => setBgDisplayColorPicker(false) } />
              <SketchPicker color={ bgColor } onChange={ (color) => {
                setBgColor(color.hex)
              } } />
            </div> : null }
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col span={ 12 }>
            <strong>Border Colour</strong>
          </Col>
          <Col span={ 12 } className="da-text-right da-mb-16">
            <div style={ styles.swatch } onClick={ () => setBorderDisplayColorPicker(!borderDisplayColorPicker) }>
              <div style={ styles.borderColor } />
            </div>
            { borderDisplayColorPicker ? <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ () => setBorderDisplayColorPicker(false) } />
              <SketchPicker color={ borderColor } onChange={ (color) => {
                setBorderColor(color.hex)
              } } />
            </div> : null }
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col span={ 12 }>
            <strong>Text Colour</strong>
          </Col>
          <Col span={ 12 } className="da-text-right da-mb-16">
            <div style={ styles.swatch } onClick={ () => setTextDisplayColorPicker(!textDisplayColorPicker) }>
              <div style={ styles.textColor } />
            </div>
            { textDisplayColorPicker ? <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ () => setTextDisplayColorPicker(false) } />
              <SketchPicker color={ textColor } onChange={ (color) => {
                setTextColor(color.hex)
              } } />
            </div> : null }
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  );


}

export default EditPetType

const rules = {
  name: [
    { required: true, message: 'Please input your name!', },
  ],
  booking_limit: [
    { required: true, message: 'Please enter booking limit', },
  ],

};
