import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { makeRequest } from '@utils/helpers';
import { useParams } from "react-router-dom";
import { getPetBookingCarePlan } from './requests';
const webData = localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {};
const moment = require('moment');

const BookingCarePlan = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();


  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getPetBookingCarePlan, id, onSuccess, onError);
  }

  const onSuccess = (data) => {
    setData(data);
  }

  const onError = (error) => {

  }


  if (!data) return null;

  return (
    <>
      <div>
        <Row gutter={ 16 } className="da-text-center">
          <Col span={ 24 }><h2>Booking Pet Care Plan</h2></Col>
        </Row>
        <Row gutter={ 16 }>
          <Col span={ 24 } className="daycareplan">
            <table border="1" width="100%">
              <tr>
                <td><strong>Arrival</strong></td>
                <td>
                  { data.map((item) =>
                    <div>{ item.pet.pet_type.name } : { item.pet.name }: { moment(item.booking.start_date).format('DD/MM/YYYY') } <br /></div>
                  ) }
                </td>
                <td><strong>Departure</strong></td>
                <td>
                  { data.map((item) =>
                    <div>{ item.pet.pet_type.name } : { item.pet.name }: { moment(item.booking.end_date).format('DD/MM/YYYY') } <br /></div>
                  ) }
                </td>
              </tr>
              <tr>
                <td><strong>Pet Name</strong></td>
                <td>
                  { data.map((item) =>
                    <div>{ item.pet.pet_type.name } : { item.pet.name }<br /></div>
                  ) }
                </td>
                <td><strong>Pet DOB</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.name } : { item.pet.dob ? moment(item.pet.dob).format('DD/MM/YYYY') : null } <strong>{ item.pet.dob ? moment().diff(moment(item.pet.dob), 'years') : 0 } year</strong><br />
                    </div>
                  ) }
                </td>
              </tr>
              <tr>
                <td><strong>Pet Breed</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.breed }<br />
                    </div>
                  ) }
                </td>
                <td><strong>Owner</strong></td>
                <td>
                  { data[0].pet.client.name }
                </td>
              </tr>
              <tr>
                <td><strong>Contact</strong></td>
                <td>

                  Telephone: { data[0].pet.client.clientinfo.phone }<br />
                  Mobile: { data[0].pet.client.clientinfo.mobile }<br />
                  Email: { data[0].pet.client.email }
                </td>
                <td><strong>Address </strong></td>
                <td >
                  { data[0].pet.client.clientinfo.address_line1 }<br />
                  { data[0].pet.client.clientinfo.address_line2 }</td>
              </tr>

              <tr>
                <td><strong>Vaccination <br />Date Given</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.vaccination_date ? moment(item.pet.vaccination_date).format('DD/MM/YYYY') : null }<br />
                    </div>
                  ) }
                </td>
                <td><strong>Kennel Cough <br />Date Given</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.kennel_cough_date ? moment(item.pet.kennel_cough_date).format('DD/MM/YYYY') : null }<br />
                    </div>
                  ) }
                </td>
              </tr>
              <tr>
                <td><strong>Netured / <br />Spayed</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.neutered_spayed ? <strong>Yes</strong> : <strong>No</strong> }<br />
                    </div>
                  ) }
                </td>
                <td><strong>Flee / Tick</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.flee_tick_treatment ? <strong>Yes</strong> : <strong>No</strong> }<br />
                    </div>
                  ) }
                </td>
              </tr>
              <tr>
                <td><strong>Wormed</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.wormed ? <strong>Yes</strong> : <strong>No</strong> }<br />
                    </div>
                  ) }
                </td>
                <td><strong>MicroChip # </strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.microchip_no }<br />
                    </div>
                  ) }
                </td>
              </tr>
              <tr>
                <td><strong>Meal Times</strong></td>
                <td colspan="3">
                  { data.map((item) => {
                    return (
                      <Row key={ item.id }>
                        { item.pet.petdiets &&
                          item.pet.petdiets.map((diet, index) => {
                            return (
                              <Col span={ 8 } key={ index }>
                                <strong>{ diet.title }</strong>
                                <br />
                                { diet.time }
                                <br />
                                { diet.description }
                                <br />
                                <br />
                              </Col>
                            );
                          }) }
                      </Row>
                    );
                  }) }
                </td>
              </tr>
              <tr>
                <td><strong>Medication Types <br />& Treatment <br />Times</strong></td>
                <td colspan="3">
                  { data.map((item) => {
                    return (
                      <Row key={ item.id }>
                        { item.pet.petmedication &&
                          item.pet.petmedication.map((medication, index) => {
                            return (
                              <Col span={ 8 } key={ index }>
                                <strong>{ medication.title }</strong><br />
                                { medication.time }
                                { medication.description }
                              </Col>
                            );
                          }) }
                      </Row>
                    );
                  }) }
                </td>
              </tr>
              <tr>
                <td><strong>Emergency Contacts</strong></td>
                <td>
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :  { item.pet.vet_name }<br />
                    </div>
                  ) }
                </td>
                <td colspan="4">
                  <Row>
                    { data.map((item) => {
                      const petTypeAndName = `${item.pet.pet_type.name} - ${item.pet.name}`;
                      return (
                        <Col span={ 8 } className="da-col-4">
                          <strong>{ petTypeAndName }</strong>
                          <div>{ item.pet.vet_name }</div>
                          <div>{ item.pet.vet_address_line1 }</div>
                          <div>{ item.pet.vet_address_line2 }</div>
                          <div>{ item.pet.vet_phone }</div>
                          <div>{ item.pet.vet_emergency_phone }</div>
                        </Col>
                      );
                    }) }
                  </Row>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  { data.map((item) =>
                    <div>
                      { item.pet.pet_type.name } : { item.pet.name } :   ({ item.pet.own_vet ? <strong>Yes</strong> : <strong>No</strong> })  <small>{ webData ? webData.pet_term_text : "You Accept Booking System Terms" }</small><br />
                    </div>
                  ) }


                </td>
              </tr>
            </table>
            <Row gutter={ 16 } className="da-mt-32">
              <Col span={ 8 }><h4>Name __________</h4></Col>
              <Col span={ 8 }><h4>Signed __________</h4></Col>
              <Col span={ 8 }><h4>Date __________</h4></Col>
            </Row>

          </Col>
        </Row>

      </div>
    </>
  );
}

export default BookingCarePlan;