import { Layout } from "antd";
import { React } from 'react';
const { Footer } = Layout;
const webData = localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {};


const MenuFooter = () => {
  return (
    <Footer className="da-bg-color-black-10">
      <p className="da-badge-text da-mb-0">
        { webData ? webData.footer_text : <>COPYRIGHT ©2023 , All rights Reserved</> }
      </p>
    </Footer>
  );
};

export default MenuFooter;