import { get, post, del, put } from "@utils/axios";

const api = "pets-management/pet-diets";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getPetDietRequest = (payload) => {
  return get(api, payload);
}

export const getPetDiet = (id) => {
  return get(`${api}/${id}`);
}

export const createPetDiet = (payload) => {
  return post(api, payload);
}

export const updatePetDiet = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const deletePetDiet = (id) => {
  return del(`${api}/${id}`);
}

