import React from 'react'
import tick from '@assets/img/tick.jpg'

const PaymentSuccess = () => {
  return (
    <div className='da-text-center'>
      <img src={ tick } alt='tick' />
      <h3>Payment Success</h3>
      <h4 className='da-mb-64'>Thank you your payment proceed successfully</h4>
      <h5>Close this box to continue</h5>
    </div>
  )
}

export default PaymentSuccess