import React, { useState, useEffect } from "react";
import { HeaderComponent, BodyComponent, TabComponent } from "@comps/components";
import { Icon } from '@iconify/react';
import IndexPetType from './components/petTypes/PetTypeIndex';
import IndexBookingType from './../bookingManagement/bookingtypes/IndexBookingType';
import IndexCrmSettings from './components/crmSettings/IndexCrmSettings';
import IndexPricingSetting from './components/pricingSettings/IndexPricingSetting';
import AllowDays from './components/allowdays/AllowDays';
import CrmStyling from './components/styling/CrmStyling';
import { useSelector } from 'react-redux';
import StripeIndex from './components/paymentMethods/StripeIndex';
import SmtpSettingIndex from './components/smtpSetting/SmtpSettingIndex';
import IndexBookingLimitByDate from '../bookingManagement/bookinglimitbydate/IndexBookingLimitByDate';
import IndexPetBreed from "./components/petBreeds/PetBreedIndex";
import IndexLimitByRang from './components/limitByRang/LimitByRangIndex';


const pageConfig = {
  headers: {
    title: "Settings",
    breadcrumb: [
      {
        name: "Settings",
        path: "/settings"
      }
    ]
  }
}
const IndexSetting = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const tabs = [
    {
      icon: <Icon icon="uil-voicemail-rectangle" />,
      title: "CRM Settings",
      content: <IndexCrmSettings />,
    },
    {
      icon: <Icon icon="uil-brightness-plus" />,
      title: "Payment Settings",
      content: <StripeIndex />,
    },
    {
      icon: <Icon icon="uil-brightness-plus" />,
      title: "SMTP Settings",
      content: <SmtpSettingIndex />,
    },
    {
      icon: <Icon icon="uil-calender" />,
      title: "Booking Types",
      content: <IndexBookingType />,
    },
    {
      icon: <Icon icon="uil-calender" />,
      title: "Limit By Date",
      content: <IndexBookingLimitByDate />,
    },
    {
      icon: <Icon icon="uil-calender" />,
      title: "Limit By Range",
      content: <IndexLimitByRang />,
    },
    {
      icon: <Icon icon="uil-raindrops-alt" />,
      title: "Pet Types",
      content: <IndexPetType />,
    },
    {
      icon: <Icon icon="uil-raindrops-alt" />,
      title: "Pet Breeds",
      content: <IndexPetBreed />,
    },
    {
      icon: <Icon icon="uil-pricetag-alt" />,
      title: "Price Setting",
      content: <IndexPricingSetting />,
    },
    {
      icon: <Icon icon="uil-calender" />,
      title: "Allowed Days",
      content: <AllowDays />,
    },
    {
      icon: <Icon icon="logos:ant-design" />,
      title: "CRM Styling",
      content: <CrmStyling />,
    },

  ]

  return (
    <>
      <style>{ `.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { color: ${crmStyle.text_color}; }` }</style>
      <style>{ `.ant-tabs-tab-btn:hover { color: ${crmStyle.text_color}; }` }</style>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-mt-64">
          <TabComponent headers={ false } tabs={ tabs } loader={ loader }></TabComponent>
        </div>
      </BodyComponent>
    </>
  );
}

export default IndexSetting;
