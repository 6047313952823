import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Upload } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { SaveButton, BodyComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { updateClient, deleteImage, getClient } from "../requests";
import { HeaderComponent } from '../../../wrappers/components';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const pageConfig = {
  headers: {
    title: "Update Client",
    breadcrumb: [
      {
        name: "Clients",
        path: "/client-management/clients"
      },
      {
        name: "Update Client",
        path: "/client-management/clients/update"
      }
    ]
  }
}
const formName = "updateClient";

const EditClient = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const [data, setData] = useState({
    object: null,
    dependencies: []
  });

  // Image upload Start
  const [fileList, setFileList] = useState([]);


  const handlePreview = async (file) => {
    if (fileList.length > 0) {
      if (fileList[0].url) {
        window.open(fileList[0].url, "_blank")
      } else {
        window.open(process.env.REACT_APP_API_BASE_URL.slice(0, -4) + fileList[0].response.url, "_blank")
      }
    }
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={ {
          marginTop: 8,
          width: '200px',
        } }
      >
        Upload
      </div>
    </div>
  );
  // Image upload End


  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getClient, id, onSuccess, onDataError);
  }

  const onFinish = (formdata) => {
    let payload = { "object": formdata }
    payload.object.id = data.object.id;
    if (fileList.length > 0 && fileList[0].response) {
      payload.object.image = fileList[0].response.url;
    } else if (fileList.length > 0 && fileList[0].url) {
      payload.object.image = fileList[0].url
    } else {
      payload.object.image = null
    }
    makeRequest(setLoader, updateClient, payload, onUpdateSuccess, onUpdateError);
  }

  const onUpdateSuccess = (data, res) => {
    notify("Client", res.msg);
  }

  const onUpdateError = (res) => {
    notify("Client", res.msg);
  }

  const onSuccess = (res) => {
    let orgId = res.object.id;
    let imageObj = res.object.image;
    let obj = Object.assign(res.object, res.object.clientInfo);
    obj.id = orgId;
    setData({ object: obj });
    setFileList([{ "id": imageObj.id, "url": process.env.REACT_APP_API_BASE_URL.slice(0, -4) + imageObj.url, "name": imageObj.name, "status": "done", "uid": imageObj.id }]);
  }

  const onDataError = (res) => {
    if(res.code === 403){
    notify("Error",res.msg, "error")
  }
  }

  const onRemoveImage = (file) => {
    makeRequest(setLoader, deleteImage, file.response ? file.response.id : file.id, onSuccess, onDataError);
  }

  if (data.object === null) {
    return null;
  }

  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-p-32">
          <Form
            layout="vertical"
            name={ formName }
            initialValues={ data.object }
            onFinish={ onFinish }
          >
            <h5 className="da-mb-24 color-lightgrey">Account Detail</h5>
            <Row gutter={ 16 }>
              <Col span={ 18 }>
                <Form.Item name="name" rules={ rules.name } label="Full Name" className="da-mb-16"
                  { ...getErrorProps(errors['name']) }
                >
                  <Input placeholder="Enter client name" />
                </Form.Item>

                <Form.Item name="email" rules={ rules.email } label="Email" className="da-mb-16"
                  { ...getErrorProps(errors['email']) }
                >
                  <Input placeholder="Enter client email" />
                </Form.Item>

                <Form.Item name="password" label="New Password" className="da-mb-16">
                  <Input placeholder="Enter password if you want to change" />
                </Form.Item>
              </Col>
              <Col span={ 6 }>
                <label>Image</label>
                <Upload
                  action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
                  listType="picture-card"
                  fileList={ fileList }
                  onPreview={ handlePreview }
                  onChange={ handleChange }
                  onRemove={ onRemoveImage }
                >
                  { fileList.length === 1 ? null : uploadButton }
                </Upload>

              </Col>
            </Row>
            <h5 className="da-mb-24 color-lightgrey">User Detail</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item rules={ rules.phone } { ...getErrorProps(errors['phone']) } name="phone" label="Telephone Number" className="da-mb-16">
                  <Input placeholder="Enter Telephone Number" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item rules={ rules.mobile } { ...getErrorProps(errors['mobile']) } name="mobile" label="Mobile Number" className="da-mb-16">
                  <Input placeholder="Enter Mobile Number" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item rules={ rules.address_line1 } { ...getErrorProps(errors['address_line1']) } name="address_line1" label="Address line 1" className="da-mb-16">
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item rules={ rules.address_line2 } { ...getErrorProps(errors['address_line2']) } name="address_line2" label="Address line 2" className="da-mb-16">
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="city" label="City" className="da-mb-16">
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="town" label="Town" className="da-mb-16">
                  <Input placeholder="Enter Town" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="postal_code" label="Postal Code" className="da-mb-16">
                  <Input placeholder="Enter Postal Code" />
                </Form.Item>
              </Col>
            </Row>

            <h5 className="da-mb-24 color-lightgrey">Other Detail</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="partner_name" label="Emergency Name" className="da-mb-16">
                  <Input placeholder="Enter partner name" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="partner_ice_phone" label="ICE Telephone" className="da-mb-16">
                  <Input placeholder="Enter ICE Name / Telephone" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="partner_ice_email" label="ICE Email" className="da-mb-16">
                  <Input placeholder="Enter Emergency Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={ 16 }>
              <Col span={ 24 } className="da-text-right da-pt-32">
                <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } text="Update Client" form={ formName } key="create_button" htmlType="submit" state={ loader } />
              </Col>
            </Row>
          </Form>
        </div>
      </BodyComponent>
    </>
  );
}

export default EditClient

const rules = {
  name: [
    { required: true, message: 'Please enter client name', },
  ],
  email: [
    { type: "email", message: "The input is not valid email" },
    { required: true, message: "Please input client email" },
  ],
  password: [
    { required: true, message: 'Please enter password', },
  ],
  phone: [
    { required: true, message: 'Please enter phone', },
  ],
  mobile: [
    { required: true, message: 'Please enter mobile', },
  ],
  address_line1: [
    { required: true, message: 'Please enter address', },
  ],
  address_line2: [
    { required: true, message: 'Please enter address', },
  ],
};
