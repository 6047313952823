import { useEffect } from 'react';

const PermissionDenied = () => {

  useEffect(() => {
    document.title = 'Permission Denied';
    //redirect after 5 seconds
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  }, []);


  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Permission Denied</h1>
          <p>You do not have permission to access this page.</p>
        </div>
      </div>
    </div>
  );
}

export default PermissionDenied;