import { ModalComponent } from "@comps/components";

const StripePay = (props) => {

  const footer = [
  ];
  return (
    <ModalComponent width={ 600 } mainTitle="Invoice" subTitle="Deposit Payment" visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>
      <iframe title="payment" src={ `${process.env.REACT_APP_API_BASE_URL}/stripe/${props.data.id}` } width="100%" height="500" allowtransparency="true" style={ { border: 'none' } } allow="encrypted-media"></iframe>
    </ModalComponent>
  )
}

export default StripePay