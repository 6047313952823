import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, DatePicker, Checkbox, Upload } from "antd";
import { useParams, useHistory } from "react-router-dom";

import { SaveButton, BodyComponent, HeaderComponent, ButtonComponent } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps, notify } from "@utils/helpers";
import { updatePet, getPetDependencies, deleteImage, getPet } from "../../requests";
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
const userData = JSON.parse(localStorage.getItem("user"));

const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Update Pet Detail",
    breadcrumb: [
      {
        name: "Pets",
        path: "/pet-management/pets"
      },
      {
        name: "Update Pet",
        path: "/pet-management/pets"
      }
    ]
  }
}

const formName = "createPet";
const { TextArea } = Input;
const EditClientPet = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [webData, setWebData] = useState(null);
  const history = useHistory();
  const [booleanData, setBooleanData] = useState({
    own_vet: false,
    neutered_spayed: false,
    wormed: false,
    flee_tick_treatment: false,
  })
  const [data, setData] = useState("");
  const [deps, setDeps] = useState({
    types: [],
    breeds:[]
  });

  // Image upload Start
  const [fileList, setFileList] = useState([]);


  const handlePreview = async (file) => {
    if (fileList.length > 0) {
      if (fileList[0].url) {
        window.open(fileList[0].url, "_blank")
      } else {
        window.open(process.env.REACT_APP_API_BASE_URL.slice(0, -4) + fileList[0].response.url, "_blank")
      }
    }
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={ {
          marginTop: 8,
          width: '200px',
        } }
      >
        Upload
      </div>
    </div>
  );
  // Image upload End

  const getSelectFieldsData = () => {
    makeRequestStateless(getPetDependencies, null, onDependencySuccess, null);
  }

  useEffect(() => {
    let webData = localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {};
    setWebData(webData);
    getSelectFieldsData();
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getPet, id, onDataSuccess, onError);
  }

  const onDataSuccess = (res) => {
    let data = res.object;

    setBooleanData({
      own_vet: data.own_vet,
      neutered_spayed: data.neutered_spayed,
      wormed: data.wormed,
      flee_tick_treatment: data.flee_tick_treatment,
    })
    if (data.dob) {
      data.dob = moment(data.dob);
    }
    if (data.vaccination_date) {
      data.vaccination_date = moment(data.vaccination_date);
    }
    if(deps.breeds.length>0){
      var petBreed = deps.breeds.filter((e)=>{
        return e.label===data.breed
      });
      data.breed = petBreed[0].value
    }
    if (data.kennel_cough_date) {
      data.kennel_cough_date = moment(data.kennel_cough_date);
    }
    setData(data);
    if (res.object.image) {
      let imageObj = res.object.image;
      setFileList([{ "id": imageObj.id, "url": process.env.REACT_APP_API_BASE_URL.slice(0, -4) + imageObj.url, "name": imageObj.name, "status": "done", "uid": imageObj.id }]);
    }
  }

  const onDependencySuccess = (data, res) => {
    setDeps({
      types: data.types,
      breeds: data.breeds
    });
  }
  const onFinish = (dataForm) => {
    let payload = { "object": dataForm }
    if(deps.breeds.length>0){
      var petBreed = deps.breeds.filter((e)=>{
        return e.value===dataForm.breed
      });
      payload.object.breed = petBreed[0].label
    }
    payload.object.id = id;
    payload.object.own_vet = booleanData.own_vet;
    payload.object.neutered_spayed = booleanData.neutered_spayed;
    payload.object.wormed = booleanData.wormed;
    payload.object.flee_tick_treatment = booleanData.flee_tick_treatment;
    if (payload.object.dob) {
      payload.object.dob = moment(payload.object.dob).format('YYYY-MM-DD');
    }
    if (payload.object.vaccination_date) {
      payload.object.vaccination_date = moment(payload.object.vaccination_date).format('YYYY-MM-DD');
    }
    if (payload.object.kennel_cough_date) {
      payload.object.kennel_cough_date = moment(payload.object.kennel_cough_date).format('YYYY-MM-DD');
    }
    if (fileList.length > 0 && fileList[0].response) {
      payload.object.image = fileList[0].response.url;
    } else if (fileList.length > 0 && fileList[0].url && data.image) {
      payload.object.image = data.image.url
    } else {
      payload.object.image = null
    }
    payload.object.client_id = userData.id;
    makeRequest(setLoader, updatePet, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Pet", res.msg);
    // history.push(`/pet-management/pets`);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    errorList['email'] = err.email;
    setErrors(errorList);
  }

  const onRemoveImage = (file) => {
    makeRequest(setLoader, deleteImage, file.response ? file.response.id : file.id, onSuccess, onError);
  }

  const editComponent = (key) => {
    history.push(`/pet-management/pets/view/${id}/?tab=${key}`);
  }

  if (data === "" || webData === null) {
    return null;
  }

  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
        <ButtonComponent onClick={ () => editComponent(1) } style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } type="primary" >Edit Dite</ButtonComponent>
        <ButtonComponent onClick={ () => editComponent(2) } style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } type="primary" >Edit Medical</ButtonComponent>
        <ButtonComponent onClick={ () => editComponent(3) } style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } type="primary" >Edit Document</ButtonComponent>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-p-32">
          <Form
            layout="vertical"
            name={ formName }
            onFinish={ onFinish }
            initialValues={ data }
          >
            <h5 className="da-mb-24 color-lightgrey">Pet Detail</h5>
            <Row gutter={ 16 }>
              <Col span={ 9 }>
                <Form.Item name="name" rules={ rules.name } label="Pet Name" className="da-mb-16"
                  { ...getErrorProps(errors['name']) }
                >
                  <Input placeholder="Enter pet name" />
                </Form.Item>
                {deps.breeds.length>0 ?<>
                <Form.Item name="breed" rules={ rules.breed } label="Breed" className="da-mb-16"
                  { ...getErrorProps(errors['breed']) }
                >
                  <Select options={deps.breeds} placeholder="Please Select breed" />
                
                </Form.Item>
                <small style={{
                  color: 'red',
                }}>	If your breed is not on the list please call before trying to book </small>
                </>
                :
                <Form.Item name="breed" rules={ rules.breed } label="Breed" className="da-mb-16"
                  { ...getErrorProps(errors['breed']) }
                >
                  <Input placeholder="Enter pet breed" />
                </Form.Item>
                }

              </Col>
              <Col span={ 9 }>
                <Form.Item name="pet_type_id" label="Pet Type :" rules={ rules.pet_type_id } className="da-mb-16"
                  { ...getErrorProps(errors['pet_type_id']) }
                >
                  <Select
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    placeholder="Select a pet type"
                    options={ deps.types }
                  />
                </Form.Item>
                <Form.Item name="dob" rules={ rules.dob } label="Date of Birth" className="da-mb-16"
                  { ...getErrorProps(errors['dob']) }
                >
                  <DatePicker format="DD/MM/YYYY" className="da-w-100" />

                </Form.Item>
              </Col>
              <Col span={ 6 }>
                <label>Image</label>
                <Upload
                  action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
                  listType="picture-card"
                  fileList={ fileList }
                  onPreview={ handlePreview }
                  onChange={ handleChange }
                  onRemove={ onRemoveImage }
                >
                  { fileList.length === 1 ? null : uploadButton }
                </Upload>
              </Col>
            </Row>
            <Row gutter={ 16 }>
              <Col span={ 18 }>
                <div className="da-mb-16">
                  <strong>Pet Description (Colour Markings, Features)</strong>
                </div>
                <Form.Item name="description" className="da-mb-16">
                  <TextArea rows={ 4 } placeholder="Enter anything" />
                </Form.Item>
              </Col>
            </Row>

            <h5 className="da-mb-24 color-lightgrey">Vet Details</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="vet_name" rules={ rules.vet_name } label="Vet Name" className="da-mb-16"
                  { ...getErrorProps(errors['vet_name']) }
                >
                  <Input placeholder="Enter vet name" />
                </Form.Item>
              </Col>
              <Col xs={ 8 } xl={ 24 }>
                <Form.Item name="vet_phone" rules={ rules.vet_phone } label="Vet Telephone No" className="da-mb-16"
                  { ...getErrorProps(errors['vet_phone']) }
                >
                  <Input placeholder="Enter vet phone" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="vet_emergency_phone" rules={ rules.vet_emergency_phone } label="Emergency vet telephone" className="da-mb-16"
                  { ...getErrorProps(errors['vet_emergency_phone']) }
                >
                  <Input placeholder="Enter vet emergency phone" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="vet_address_line1" rules={ rules.vet_address_line1 } label="Address Line 1" className="da-mb-16"
                  { ...getErrorProps(errors['vet_address_line1']) }
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="vet_address_line2" rules={ rules.vet_address_line2 } label="Address Line 2" className="da-mb-16"
                  { ...getErrorProps(errors['vet_address_line2']) }
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="city" rules={ rules.city } label="City" className="da-mb-16"
                  { ...getErrorProps(errors['city']) }
                >
                  <Input placeholder="Enter city" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="postal_code" rules={ rules.postal_code } label="Postal Code" className="da-mb-16"
                  { ...getErrorProps(errors['postal_code']) }
                >
                  <Input placeholder="Enter postal code" />
                </Form.Item>
              </Col>
              <Col span={ 24 }>
                <Form.Item name="own_vet" rules={ rules.own_vet } className="da-mb-16"
                  { ...getErrorProps(errors['own_vet']) }
                >
                  <Checkbox id="ownvet" onChange={ (event) => {
                    setBooleanData({
                      ...booleanData,
                      own_vet: event.target.checked
                    })
                  } } checked={ booleanData.own_vet } /> <strong><label htmlFor="ownvet"> { webData.pet_term_text } </label></strong>
                </Form.Item>
              </Col>
              <Col span={ 24 }>
                <h5 className="da-mb-24 color-lightgrey">Other Details</h5>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="microchip_no" rules={ rules.microchip_no } label="MicroChip" className="da-mb-16"
                  { ...getErrorProps(errors['microchip_no']) }
                >
                  <Input placeholder="Enter MicroChip #" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="vaccination_date" rules={ rules.vaccination_date } label="Vaccination Date Given" className="da-mb-16"
                  { ...getErrorProps(errors['vaccination_date']) }
                >
                  <DatePicker format="DD/MM/YYYY" className="da-w-100" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="kennel_cough_date" rules={ rules.kennel_cough_date } label="Kennel Cough Date Given" className="da-mb-16"
                  { ...getErrorProps(errors['kennel_cough_date']) }
                >
                  <DatePicker format="DD/MM/YYYY" className="da-w-100" />
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="neutered_spayed" rules={ rules.neutered_spayed } className="da-mb-16"
                  { ...getErrorProps(errors['neutered_spayed']) }
                >
                  <Checkbox
                    id="neutered"
                    onChange={ (event) => {
                      setBooleanData({
                        ...booleanData,
                        neutered_spayed: event.target.checked
                      })
                    }
                    }
                    checked={ booleanData.neutered_spayed }
                  /> <strong><label htmlFor="neutered">Neutered/Spayed</label></strong>
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="wormed" rules={ rules.wormed } className="da-mb-16"
                  { ...getErrorProps(errors['wormed']) }
                >
                  <Checkbox
                    onChange={ (event) => {
                      setBooleanData({
                        ...booleanData,
                        wormed: event.target.checked
                      })
                    }
                    }
                    checked={ booleanData.wormed }
                  /> <strong>Wormed</strong>
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="flee_tick_treatment" rules={ rules.flee_tick_treatment } className="da-mb-16"
                  { ...getErrorProps(errors['flee_tick_treatment']) }
                >
                  <Checkbox
                    onChange={ (event) => {
                      setBooleanData({
                        ...booleanData,
                        flee_tick_treatment: event.target.checked
                      })
                    }
                    }
                    checked={ booleanData.flee_tick_treatment }
                  /> <strong>Flee/Tick Treatment</strong>
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item name="insurance_name" rules={ rules.insurance_name } label="Insurance Name" className="da-mb-16"
                  { ...getErrorProps(errors['insurance_name']) }
                >
                  <Input placeholder="Enter Insurance Name" />
                </Form.Item>
              </Col>
              <Col span={ 24 } className="da-text-right">
                <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } text="Update Pet " form={ formName } key="create_button" htmlType="submit" state={ loader } />
              </Col>
            </Row>


          </Form>
        </div>
      </BodyComponent>
    </>
  );
}

export default EditClientPet

const rules = {
  name: [
    { required: true, message: 'Please enter pet name', },
  ],
  email: [
    { type: "email", message: "The input is not valid email" },
    { required: true, message: "Please input pet email" },
  ],
  password: [
    { required: true, message: 'Please enter password', },
  ],
  pet_type_id: [
    { required: true, message: 'Please select type', },
  ],
  vet_name: [
    { required: true, message: 'Please enter name', },
  ],
  vet_emergency_phone: [
    { required: true, message: 'Please enter phone', },
  ],
  vet_phone: [
    { required: true, message: 'Please enter phone', },
  ],
  microchip_no: [
  ],
};
