import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Upload } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { useSelector } from 'react-redux';
import { deleteImage, updateSettings } from "../requests";


const formName = "updateStripeForm";

const EditSmtpSetting = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);



  const onFinish = (values) => {
    let payload = {
      "object": values,
    }
    makeRequest(setLoader, updateSettings, payload, onUpdatSuccess, onUpdatError);
  }

  const onUpdatSuccess = (data, res) => {
    notify("Site Setting", res.msg);
    props.onUpdated(true);
  }

  const onUpdatError = (data) => {
    setErrors(data);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } form={ formName } key="update_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent width={ 1000 } mainTitle="Edit" subTitle="Stripe Setting" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
        initialValues={ props.data }
      >
        <Row gutter={ 16 }>
          <Col span={ 24 }>
            <Form.Item name="email_sender" rules={ rules.email_sender } label="Email sender" className="da-mb-16"
              { ...getErrorProps(errors['email_sender']) }
            >
              <Input placeholder="Enter Email sender" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="MAIL_MAILER" rules={ rules.MAIL_MAILER } label="MAIL MAILER" className="da-mb-16"
              { ...getErrorProps(errors['MAIL_MAILER']) }
            >
              <Input placeholder="Enter MAIL MAILER" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="MAIL_HOST" rules={ rules.MAIL_HOST } label="MAIL_HOST" className="da-mb-16"
              { ...getErrorProps(errors['MAIL_HOST']) }
            >
              <Input placeholder="Enter MAIL HOST" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="MAIL_PORT" rules={ rules.MAIL_PORT } label="MAIL_PORT" className="da-mb-16"
              { ...getErrorProps(errors['MAIL_PORT']) }
            >
              <Input placeholder="Enter MAIL_PORT" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="MAIL_USERNAME" rules={ rules.MAIL_USERNAME } label="MAIL_USERNAME" className="da-mb-16"
              { ...getErrorProps(errors['MAIL_USERNAME']) }
            >
              <Input placeholder="Enter MAIL_USERNAME" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="MAIL_PASSWORD" rules={ rules.MAIL_PASSWORD } label="MAIL_PASSWORD" className="da-mb-16"
              { ...getErrorProps(errors['MAIL_PASSWORD']) }
            >
              <Input placeholder="Enter MAIL_PASSWORD" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="MAIL_ENCRYPTION" rules={ rules.MAIL_ENCRYPTION } label="MAIL_ENCRYPTION" className="da-mb-16"
              { ...getErrorProps(errors['MAIL_ENCRYPTION']) }
            >
              <Input placeholder="Enter MAIL_ENCRYPTION" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="MAIL_FROM_ADDRESS" rules={ rules.MAIL_FROM_ADDRESS } label="MAIL_FROM_ADDRESS" className="da-mb-16"
              { ...getErrorProps(errors['MAIL_FROM_ADDRESS']) }
            >
              <Input placeholder="Enter MAIL_FROM_ADDRESS" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalComponent>
  );
}

export default EditSmtpSetting

const rules = {
  email_sender: [
    { required: true, message: 'Please input your email_sender!', },
  ],
  MAIL_MAILER: [
    { required: true, message: 'Please input your MAIL_MAILER!', },
  ],

};
