import { get, post, del, put } from "@utils/axios";

const api = "booking-management/booking-types";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getBookingTypeRequest = (id) => {
  return get(api);
}

export const getBookingType = (id) => {
  return get(`${api}/${id}`);
}

export const createBookingType = (payload) => {
  return post(api, payload);
}

export const updateBookingType = (payload) => {
  return put(`${api}/${payload.object.id}`, payload);
}

export const deleteBookingType = (id) => {
  return del(`${api}/${id}`);
}

