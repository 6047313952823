import React, { useState, useEffect } from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { Layout, Button } from "antd";
import { RiArrowUpLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPermissions } from "@state/commonSlice";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ModuleRoutes from "@mods/routes";
import { getStyleRequest } from "@mods/settings/components/styling/requests";
import { makeRequest } from "@utils/helpers";

const Main = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(getPermissions());
    if (crmStyle === null) {
      getStyleData();
    }
    // eslint-disable-next-line
  }, []);

  const getStyleData = () => {
    makeRequest(Function, getStyleRequest, null, onSuccess, onError);
  };

  const onSuccess = (data, res) => {
    localStorage.setItem("crmStyle", JSON.stringify(data));
    window.location.reload();
  }

  const onError = (err) => {

  }

  if (crmStyle === null) {
    return null;
  }

  return (
    <div>
      <style>{ `.ant-breadcrumb a {color: ${crmStyle.text_color}}.ant-menu-item a:hover{color: ${crmStyle.text_color}}.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {border-color:${crmStyle.border_color}}.ant-picker-calendar-date-value {color: ${crmStyle.text_color}}.ant-spin-dot-item {
        background-color: ${crmStyle.bg_color};
      }.ant-tooltip-inner{background:${crmStyle.bg_color} !important;color:white}}.ant-picker-calendar-date-value{
        color: ${crmStyle.text_color} !important;
      }.ant-select-selector:hover, .ant-select-selector:focus-within {
  border-color: ${crmStyle.bg_color} !important;
  box-shadow: none !important;
        }.ant-input:hover, .ant-input:focus-within {
  border-color: ${crmStyle.bg_color} !important;
  box-shadow: none;
        }.ant-picker:hover, .ant-picker:focus-within {
  border-color: ${crmStyle.bg_color} !important;
}.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {border-color:${crmStyle.bg_color}}.ant-picker-today-btn {color:${crmStyle.bg_color}}.ant-picker-focused { box-shadow: none;border-color:${crmStyle.bg_color}}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color:${crmStyle.bg_color} !important;}
.ant-tabs-tab-btn:hover {color:${crmStyle.bg_color} !important;}
.ant-tabs-tab:hover {color:${crmStyle.bg_color} !important;}
.ant-select-item-option-content {
  color: ${crmStyle.text_color}; /* Change to the desired text color */
}
.rmdp-day.rmdp-today span {
  background-color: ${crmStyle.bg_color};
}
.rmdp-week-day {
  color: ${crmStyle.bg_color};
}
.rmdp-arrow {
 border-color: ${crmStyle.bg_color};
}
.ant-popover-message .anticon svg{
        fill:${crmStyle.bg_color};
      }
.rmdp-arrow-container:hover {
  background-color: ${crmStyle.bg_color};
}
.rmdp-day span:hover{
  background-color: ${crmStyle.bg_color};
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: ${crmStyle.bg_color};
}
.rmdp-panel-body li, .rmdp-panel-body li.bg-blue {
  background-color: ${crmStyle.bg_color};
}
` }</style>
      <Layout className="da-app-layout">
        <Sidebar visible={ visible } setVisible={ setVisible } />

        <Layout>
          <MenuHeader setVisible={ setVisible } />

          <Layout.Content className="da-content-main da-bg-black-0">
            <Switch>
              <Route path={ path } component={ ModuleRoutes } />
            </Switch>
          </Layout.Content>

          <MenuFooter />
        </Layout>
      </Layout>

      <div className="scroll-to-top hide-in-print">
        <ScrollToTop showUnder={ 300 } style={ {
          bottom: "5%",
        } }>
          <Button
            style={ { background: crmStyle.bg_color, border: crmStyle.border_color, } }
            shape="circle"
            icon={ <RiArrowUpLine /> }
          />
        </ScrollToTop>
      </div>
    </div>
  );
};

export default Main;
