import React, { useEffect, useState, } from "react";
import { Form, Input, Row, Col, Upload } from "antd";
import { useParams } from "react-router-dom";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { createPetMedical, deleteImage } from "../requests";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined } from '@ant-design/icons';


const formName = "createPetMedical";

const CreatePetMedical = (props) => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);

  // Image upload Start

  const [fileList, setFileList] = useState([]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      if (fileList.length > 0) {
        window.open(process.env.REACT_APP_API_BASE_URL.slice(0, -4) + fileList[0].response.url, "_blank")
      }
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={ {
          marginTop: 8,
        } }
      >
        Upload
      </div>
    </div>
  );
  // Image upload End


  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object['pet_id'] = id;
    if (fileList.length > 0) {
      payload.object.image = fileList[0].response.url;
    }
    makeRequest(setLoader, createPetMedical, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Pet Medical", res.msg);
    props.onCreated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    errorList['email'] = err.email;
    errorList['role_id'] = err.role_id;
    errorList['company_id'] = err.company_id;
    setErrors(errorList);
  }

  const onRemoveImage = (file) => {
    makeRequest(setLoader, deleteImage, file.response.id, onSuccess, onError);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent mainTitle="Create" subTitle="Pet Medical" visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
      >
        <Row gutter={ 16 }>
          <Col span={ 18 }>
            <Form.Item name="title" rules={ rules.title } label="Title" className="da-mb-16"
              { ...getErrorProps(errors['title']) }>
              <Input placeholder="Medical Title" />
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Upload
              action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
              listType="picture-card"
              fileList={ fileList }
              onPreview={ handlePreview }
              onChange={ handleChange }
              onRemove={ onRemoveImage }
            >{ fileList.length === 1 ? null : uploadButton }
            </Upload>
          </Col>
        </Row>
        <Form.Item name="description" label="Description" className="da-mb-16">
          <TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item name="medication" label="Medication" className="da-mb-16">
          <TextArea placeholder="Medication" />
        </Form.Item>


      </Form>
    </ModalComponent>
  );


}

export default CreatePetMedical

const rules = {
  title: [
    { required: true, message: 'Please input title!', },
  ],
  time: [
    { required: true, message: 'Please select time!', },
  ],

};
