import React, { useState, useEffect } from "react";
import { TableComponent, ActionComponent, CreateButton, } from "@comps/components";
import { Row, Col } from "antd";
import CreatePetType from "./components/CreatePetType";
import { makeRequest, removeById, notify, replaceById } from '@utils/helpers';
import { getPetTypeRequest, deletePetType } from './requests';
import EditPetType from './components/EditPetType';
import { useSelector } from 'react-redux';

const IndexPetType = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
      
    },
    {
      key: 'name',
      title: 'Pet Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'booking_limit',
      title: 'Booking Limit',
      dataIndex: 'booking_limit',
      sorter: true,
    },
    {
      key: 'bg_color',
      title: 'BG Color',
      dataIndex: 'bg_color',
      sorter: true,
      render: (record) => <><div className="da-mt-4" style={ {
        width: '15px', height: '15px', background: record, float: 'left', marginRight: '5px'
      } }></div> { record }</>
    },
    {
      key: 'border_color',
      title: 'Border Color',
      dataIndex: 'border_color',
      sorter: true,
      render: (record) => <><div className="da-mt-4" style={ {
        width: '15px', height: '15px', background: record, float: 'left', marginRight: '5px'
      } }></div> { record }</>
    },
    {
      key: 'text_color',
      title: 'Text Color',
      dataIndex: 'text_color',
      render: (record) => <><div className="da-mt-4" style={ {
        width: '15px', height: '15px', background: record, float: 'left', marginRight: '5px'
      } }></div> { record }</>
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } />
    },
  ];

  useEffect(() => {
    makeRequest(setLoader, getPetTypeRequest, pagination, onSuccess, onError);
  }, [pagination]);

  const onSuccess = (response) => {
    setDataSource(response.data);
    setTotalRecords(response.total);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreatePetType crmStyle={ crmStyle } onCreated={ onCreated } />);
  }

  const onCreated = (each) => {
    if (each) {
      setDataSource([each.object, ...dataSource]);
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }


  const onEdit = (record) => {
    setChildComponent(<EditPetType crmStyle={ crmStyle } id={ record.id } onUpdated={ onUpdated } />);
  }

  const onUpdated = (each) => {
    if (each) {
      setDataSource(replaceById(dataSource, each.object));
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deletePetType, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify(msg.msg)
  }

  const onError = (error, msg) => {
    //
  }




  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }






  return (
    <>
      { childComponent }
      <div className="rightBox">
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 12 }><h4>Pet Types</h4></Col>
              <Col span={ 12 } className="da-text-right"><CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } text="Add Type " /></Col>
            </Row>
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </div>
    </>
  );
}

export default IndexPetType;