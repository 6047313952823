import React from "react";
import { Tabs, Skeleton } from "antd";

import { HeaderComponent, BodyComponent } from "@comps/components";

const BaseTab = (props) => {
  let child = <Skeleton active />;
  if (!props.loader) {
    child = (
      <Tabs onChange={ props.onChange } activeKey={ props.activeKey } tabPosition={ props.position } type="card" >
        { props.tabs.map((tab, index) => (
          <>
            { tab.disabled ? null :
              <Tabs.TabPane tab={ <> { tab.icon } { tab.title }</> } key={ index } >
                { tab.content }
              </Tabs.TabPane>
            }
          </>
        )) }
      </Tabs>
    );
  }
  return (
    <>
      { props.headers ? <HeaderComponent headers={ props.headers }>
      </HeaderComponent> : null }
      <BodyComponent hideLine>
        { child }
      </BodyComponent>
    </>
  );
}

export default BaseTab;
