import { Icon } from '@iconify/react';


const navigation = [
    {
        id: "dashboard",
        title: "Dashboard",
        icon: <Icon icon="uil-dashboard" />,
        uri: '',
        permissionKey: 'dashboard',
    },
    {
        id: "availability",
        title: "Availability Check",
        icon: <Icon icon="uil-bed-double" />,
        uri: 'availability',
        permissionKey: 'availabilityAllow',
    },
    {
        id: "bookings",
        title: "Bookings",
        icon: <Icon icon="uil-bag-alt" />,
        uri: 'booking-management/bookings',
        permissionKey: 'bookingAllow',
    },
    {
        id: "pets",
        title: "Pets",
        icon: <Icon icon="uil-reddit-alien-alt" />,
        uri: 'pet-management/pets',
        permissionKey: 'petAllow',
    },
    {
        id: "clients",
        title: "Clients",
        icon: <Icon icon="mdi:user-group" />,
        uri: 'client-management/clients',
        permissionKey: 'clientAllow',
    },

    {
        id: "attendance",
        title: "Attendance",
        icon: <Icon icon="uil-calendar-alt" />,
        uri: 'pet-management/attendance',
        permissionKey: 'attendanceAllow',
    },
    {
        id: "profile",
        title: "My Profile",
        icon: <Icon icon="mdi:user" />,
        uri: "client-management/profile",
        permissionKey: 'perofileAllow',
    },
    {
        id: "transactions",
        title: "Transactions",
        icon: <Icon icon="uil-euro-circle" />,
        uri: 'transactions',
        permissionKey: 'transactionAllow',
    },
    {
        id: "Settings",
        title: "Settings",
        icon: <Icon icon="uil-setting" />,
        uri: '',
        permissionKey: 'settingAllow',
        children: [
            {
                id: "usersManagement",
                title: "Users Management",
                uri: "/user-management/users",
                permissionKey: 'settingAllow',
            },
            {
                id: "rolesManagement",
                title: "Roles Management",
                uri: "/user-management/roles",
                permissionKey: 'settingAllow',
            },
            {
                id: "settings",
                title: "Site Settings",
                icon: '',
                uri: '/settings',
                permissionKey: 'settingAllow',
            }
        ]
    },
    {
        id: "Reports",
        title: "Reports",
        icon: <Icon icon="uil-chart-growth" />,
        uri: '',
        permissionKey: 'reportAllow',
        children: [
            {
                id: "petReport",
                title: "Pet Reports",
                uri: "/report-management/pets",
                permissionKey: 'reportAllow',
            },
            {
                id: "bookingReport",
                title: "Booking Reports",
                uri: "/report-management/bookings",
                permissionKey: 'reportAllow',
            }
        ]
    },
    {
        id: "salesReport",
        title: "Sale Report",
        icon: <Icon icon="uil-graph-bar" />,
        uri: "report-management/sales",
        permissionKey: 'saleReportAllow',
    },

];

export default navigation
