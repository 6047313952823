import React, { useState, useEffect } from "react";
import { TableComponent, ActionComponent, CreateButton } from "@comps/components";
import { Row, Col } from "antd";
import CreatePetType from "./components/CreatePetDocument";
import { makeRequest, removeById, notify, replaceById } from '@utils/helpers';
import { getPetDocumentRequest, deletePetDocument } from './requests';
import EditPetType from './components/EditPetDocument';
import { useSelector } from 'react-redux';


const IndexPetDocument = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({ pet_id: props.id });
  const [childComponent, setChildComponent] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
    },
    {
      key: 'image',
      title: 'File',
      dataIndex: 'image',
      render: (record) => record ? <a href={ process.env.REACT_APP_API_BASE_URL.slice(0, -4) + record } target="_blank">Attachment</a> : null
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } />
    },
  ];

  useEffect(() => {
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters
    };
    makeRequest(setLoader, getPetDocumentRequest, payload, onSuccess, onError);
  }, [pagination]);

  const onSuccess = (response) => {
    setDataSource(response.data);
    setTotalRecords(response.total);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreatePetType crmStyle={ crmStyle } onCreated={ onCreated } />);
  }

  const onCreated = (each) => {
    if (each) {
      setDataSource([each.object, ...dataSource]);
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }


  const onEdit = (record) => {
    setChildComponent(<EditPetType crmStyle={ crmStyle } id={ record.id } onUpdated={ onUpdated } />);
  }

  const onUpdated = (each) => {
    if (each) {
      setDataSource(replaceById(dataSource, each.object));
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deletePetDocument, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify("Pet Document", msg.msg)
  }

  const onError = (error, msg) => {
    //
  }




  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  return (
    <>
      { childComponent }
      <Row gutter={ 16 }>
        <Col span={ 24 } className="da-text-right da-mb-12">
          <CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } text="Add Pet Document " />
        </Col>
      </Row>
      <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
    </>
  );
}

export default IndexPetDocument;