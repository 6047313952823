import { get, post, del, put } from "@utils/axios";

const api = "configs-management/settings";

export const getSettings = () => {
  return get(`configs-management/get-settings`);
}

export const deleteImage = (id) => {
  return get(`delete-image/` + id);
}

export const updateSettings = (payload) => {
  return put(`${api}/` + 1, payload);
}