import React from "react";
import { Col, Row, Button } from "antd";
import { useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
const moment = require('moment');

const AboutPet = (props) => {
  const history = useHistory();



  const onEditBtn = () => {
    history.push(`/pet-management/pets/edit/${props.id}`);
  }

  return (
    <>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col lg={ 22 } xs={ 18 }>
          <h5>Pet Detail</h5>
        </Col>
        <Col lg={ 2 } xs={ 4 } className="da-pr-16">
          <Button onClick={ onEditBtn } type="text"><EditOutlined /></Button>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Name
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.name }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Pet Type
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.type_name }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Breed
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.breed }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Pet Date Of Birth
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.dob ? moment(props.data.dob).format('DD/MM/YYYY') : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Pet Description (Colour Markings, Features)
        </Col>
        <Col span={ 12 } className="da-text-right">
          { props.data.description }
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 22 } className="da-mb-24 da-mt-24">
          <h5>Vet Details</h5>
        </Col>
        <Col span={ 12 }>
          In case of an emergency, do you wish us to call our vets ( Parkhill Wetherby) or do you wish us to contact your vets?
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.own_vet ? "Yes" : "No" }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Vet Name
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.vet_name }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Vet Telephone No.
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.vet_phone }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Emergency vet
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.vet_emergency_phone }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Vet Address
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.vet_address_line1 } { props.data.vet_address_line2 }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Vet City
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.city }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Vet Postal Code
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.postal_code }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 22 } className="da-mb-24 da-mt-24">
          <h5>Other Details</h5>
        </Col>
        <Col span={ 12 }>
          Microchip #
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.microchip_no }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Vaccination Date Given
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.vaccination_date ? moment(props.data.vaccination_date).format('DD/MM/YYYY') : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Kennel Cough Date Given
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.kennel_cough_date ? moment(props.data.kennel_cough_date).format('DD/MM/YYYY') : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Insurance Name
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.insurance_name }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Neutered/Spayed
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.neutered_spayed ? "Yes" : "No" }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Wormed
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.wormed ? "Yes" : "No" }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Flee/Tick Treatment
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.flee_tick_treatment ? "Yes" : "No" }</strong>
        </Col>
      </Row>


    </>
  );
}

export default AboutPet;
