import { get, post, del, put } from "@utils/axios";

const api = "pets-management/pet-documents";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getPetDocumentRequest = (payload) => {
  return get(api, payload);
}

export const getPetDocument = (id) => {
  return get(`${api}/${id}`);
}

export const createPetDocument = (payload) => {
  return post(api, payload);
}

export const updatePetDocument = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const deletePetDocument = (id) => {
  return del(`${api}/${id}`);
}

export const deleteImage = (id) => {
  return get(`delete-image/` + id);
}

