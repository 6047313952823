import React from 'react'
import cross from '@assets/img/cross.jpg'
import { ButtonComponent } from "@comps/components";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const PaymentError = () => {
  const [errorMsg, setErrorMsg] = React.useState('');

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const msg = searchParams.get('msg');
    setErrorMsg(msg);
  }, []);

  const history = useHistory();

  const gotoBookings = () => {
    history.push('/booking-management/bookings');
  }
  const crmStyle = useSelector(state => state.common.crmStyle);
  return (
    <div className='da-text-center'>
      <img src={ cross } alt='cross' />
      <h3>Payment Faild</h3>
      <h4 className='da-mb-64'>{ errorMsg }</h4>
      <h5>Please Clost This Popup and try again later</h5>
    </div>
  )
}

export default PaymentError