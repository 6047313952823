import React, { useState, useEffect } from "react";
import { TableComponent, ActionComponent, CreateButton, ButtonComponent } from "@comps/components";
import { Row, Col } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { makeRequest, removeById, notify, replaceById } from '@utils/helpers';
import { getBookings, deleteBooking } from '../../requests';
import { Button, Tooltip } from "antd";
import { ContainerOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
const moment = require('moment');

const PetBookings = (props) => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [bookingStatus, setBookingStatus] = useState("Show Past Bookings");
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',

    },
    {
      key: 'pet_name',
      title: 'Pet Names',
      dataIndex: 'pet_names',
      sorter: false,
    },
    {
      key: 'start_date',
      title: 'Start',
      dataIndex: 'start_date',
      sorter: true,
      render: (each) => each ? moment(each).format('DD/MM/YYYY') : null
    },
    {
      key: 'end_date',
      title: 'End',
      dataIndex: 'end_date',
      sorter: true,
      render: (each) => each ? moment(each).format('DD/MM/YYYY') : null
    },
    {
      key: 'booking_type',
      title: 'Type',
      dataIndex: 'booking_type',
      sorter: true,
    },
    {
      key: 'total_price',
      title: 'Price',
      dataIndex: 'total_price',
      sorter: false,
    },
    {
      key: 'invoice',
      title: 'invoice',
      dataIndex: 'invoice',
      sorter: false,
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <div className="da-text-right"><Tooltip title="View Invoice" ><Button onClick={ () => history.push(`/invoice/${record.invoice}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: '#50a5f1', fontSize: '18px' } }><Icon icon="uil-invoice" /></Button></Tooltip>
        <Tooltip title="Pet Care Plan" ><Button onClick={ () => history.push(`/bookingcareplan/${record.id}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><ContainerOutlined /></Button></Tooltip><ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } /></div>
    },
  ];

  useEffect(() => {
    makeRequest(setLoader, getBookings, id, onSuccess, onError);
  }, [pagination]);

  const onSuccess = (data, response) => {
    setDataSource(response.data.filter((each) => {
      return each.end_date === null
    }));
    setOrgData(response.data);
    setTotalRecords(response.total);
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deleteBooking, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify("Booking", msg.msg)
  }

  const onError = (error, msg) => {
    //
  }

  const onEdit = (record) => {
    history.push(`/booking-management/bookings/edit/${record.id}`);
  }

  const onCreate = () => {
    history.push(`/booking-management/bookings/create`);
  }

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  const onBookingStatusChange = () => {
    if (bookingStatus === "Show Past Bookings") {
      setBookingStatus("Show Recent Bookings");
      let data = orgData.filter((each) => {
        return each.end_date !== null
      });
      setDataSource(data);
    } else {
      setBookingStatus("Show Past Bookings");
      setDataSource(orgData.filter((each) => {
        return each.end_date === null
      }));
    }
  }

  return (
    <>
      { childComponent }
      <Row gutter={ 16 }>
        <Col span={ 8 } className="da-text-left da-mb-12">
        <strong>{bookingStatus==="Show Past Bookings" ? "Recent Bookings" : "Past Bookings"}</strong>
        </Col>
        <Col span={ 16 } className="da-text-right da-mb-12">
          <ButtonComponent className="da-mr-8" type="primary" style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } onClick={ onBookingStatusChange } >{ bookingStatus }</ButtonComponent>
          <CreateButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } onClick={ onCreate } text="Make Booking " />
        </Col>
      </Row>
      <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
    </>
  );
}

export default PetBookings;