import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, DatePicker, Checkbox, Upload, message } from "antd";
import { useHistory } from "react-router-dom";

import { SaveButton, BodyComponent, HeaderComponent } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps, notify } from "@utils/helpers";
import { createPet, getPetDependencies, deleteImage } from "../../requests";
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
const moment = require('moment');
const userData = JSON.parse(localStorage.getItem("user"));

const pageConfig = {
  headers: {
    title: "Add New Pet",
    breadcrumb: [
      {
        name: "Pets",
        path: "/pet-management/pets"
      },
      {
        name: "Create Pet",
        path: "/pet-management/pets/create"
      }
    ]
  }
}

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const formName = "createPet";
const { TextArea } = Input;
const CreateClientPet = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [disableBooking, setDisableBooking] = useState(false);
  const [webData, setWebData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [booleanData, setBooleanData] = useState({
    own_vet: false,
    neutered_spayed: false,
    wormed: false,
    flee_tick_treatment: false,
  })
  const history = useHistory();
  const [deps, setDeps] = useState({
    types: [],
    breeds:[]
  });

  // Image upload Start

  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      if (fileList.length > 0) {
        window.open(process.env.REACT_APP_API_BASE_URL.slice(0, -4) + fileList[0].response.url, "_blank")
      }
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={ {
          marginTop: 8,

        } }
      >
        Upload
      </div>
    </div>
  );
  // Image upload End

  const getSelectFieldsData = () => {
    makeRequestStateless(getPetDependencies, null, onDependencySuccess, null);
  }

  useEffect(() => {
    let webData = localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {};
    setWebData(webData);
    getSelectFieldsData();
    // eslint-disable-next-line
  }, []);


  const onDependencySuccess = (data, res) => {
    setDeps({
      types: data.types,
      breeds:data.breeds
    });
  }
  const onFinish = (data) => {
    let payload = { "object": data }
    if (payload.object.dob) {
      payload.object.dob = moment(payload.object.dob).format('YYYY-MM-DD');
    }

    if(deps.breeds.length>0){
      var petBreed = deps.breeds.filter((e)=>{
        return e.value===data.breed
      });
      payload.object.breed = petBreed[0].label
    }
    if (payload.object.vaccination_date) {
      payload.object.vaccination_date = moment(payload.object.vaccination_date).format('YYYY-MM-DD');
    }
    if (payload.object.kennel_cough_date) {
      payload.object.kennel_cough_date = moment(payload.object.kennel_cough_date).format('YYYY-MM-DD');
    }
    payload.object.own_vet = booleanData.own_vet;
    payload.object.neutered_spayed = booleanData.neutered_spayed;
    payload.object.wormed = booleanData.wormed;
    payload.object.flee_tick_treatment = booleanData.flee_tick_treatment;
    payload.object.client_id = userData.id;
    if (fileList.length > 0) {
      payload.object.image = fileList[0].response.url;
    }
    makeRequest(setLoader, createPet, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Pet", res.msg);
    history.push(`/pet-management/pets`);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    errorList['email'] = err.email;
    setErrors(errorList);
  }

  const onRemoveImage = (file) => {
    makeRequest(setLoader, deleteImage, file.response.id, onSuccess, onError);
  }

  if (webData == null) {
    return null;
  }

  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-p-32">
          <Form
            layout="vertical"
            name={ formName }
            onFinish={ onFinish }
          >
            <h5 className="da-mb-24 color-lightgrey">Pet Detail</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } lg={ 9 } xl={ 9 }>
                <Form.Item name="name" rules={ rules.name } label="Pet Name" className="da-mb-16"
                  { ...getErrorProps(errors['name']) }
                >
                  <Input placeholder="Enter pet name" />
                </Form.Item>
                {deps.breeds.length>0 ?<>
                <Form.Item name="breed" rules={ rules.breed } label="Breed" className="da-mb-16"
                  { ...getErrorProps(errors['breed']) }
                >
                  <Select options={deps.breeds} placeholder="Please Select breed" />
                
                </Form.Item>
                <small style={{
                  color: 'red',
                }}>	If your breed is not on the list please call before trying to book </small>
                </>
                :
                <Form.Item name="breed" rules={ rules.breed } label="Breed" className="da-mb-16"
                  { ...getErrorProps(errors['breed']) }
                >
                  <Input placeholder="Enter pet breed" />
                </Form.Item>
                }
              </Col>
              <Col xs={ 24 } lg={ 9 } xl={ 9 }>
                <Form.Item name="pet_type_id" label="Pet Type :" rules={ rules.pet_type_id } className="da-mb-16"
                  { ...getErrorProps(errors['pet_type_id']) }
                >
                  <Select
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    placeholder="Select a pet type"
                    options={ deps.types }
                  />
                </Form.Item>
                <Form.Item name="dob" rules={ rules.dob } label="Date of Birth" className="da-mb-16"
                  { ...getErrorProps(errors['dob']) }
                >
                  <DatePicker format="DD/MM/YYYY" className="da-w-100" />

                </Form.Item>
              </Col>
              <Col xs={ 24 } lg={ 6 } xl={ 6 }>
                <label>Image</label>
                <Upload
                  action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
                  listType="picture-card"
                  fileList={ fileList }
                  onPreview={ handlePreview }
                  onChange={ handleChange }
                  onRemove={ onRemoveImage }
                >
                  { fileList.length === 1 ? null : uploadButton }
                </Upload>
              </Col>
            </Row>
            <Row gutter={ 16 }>
              <Col xs={ 24 } lg={ 18 } xl={ 18 }>
                <div className="da-mb-16">
                  <strong>Pet Description (Colour Markings, Features)</strong>
                </div>
                <Form.Item name="description" className="da-mb-16">
                  <TextArea rows={ 4 } placeholder="Enter anything" />
                </Form.Item>
              </Col>
            </Row>

            <h5 className="da-mb-24 color-lightgrey">Vet Details</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="vet_name" rules={ rules.vet_name } label="Vet Name" className="da-mb-16"
                  { ...getErrorProps(errors['vet_name']) }
                >
                  <Input placeholder="Enter vet name" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="vet_phone" rules={ rules.vet_phone } label="Vet Telephone No" className="da-mb-16"
                  { ...getErrorProps(errors['vet_phone']) }
                >
                  <Input placeholder="Enter vet phone" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="vet_emergency_phone" rules={ rules.vet_emergency_phone } label="Emergency vet telephone" className="da-mb-16"
                  { ...getErrorProps(errors['vet_emergency_phone']) }
                >
                  <Input placeholder="Enter vet emergency phone" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 } >
                <Form.Item name="vet_address_line1" rules={ rules.vet_address_line1 } label="Address Line 1" className="da-mb-16"
                  { ...getErrorProps(errors['vet_address_line1']) }
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 } >
                <Form.Item name="vet_address_line2" rules={ rules.vet_address_line2 } label="Address Line 2" className="da-mb-16"
                  { ...getErrorProps(errors['vet_address_line2']) }
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="city" rules={ rules.city } label="City" className="da-mb-16"
                  { ...getErrorProps(errors['city']) }
                >
                  <Input placeholder="Enter city" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="postal_code" rules={ rules.postal_code } label="Postal Code" className="da-mb-16"
                  { ...getErrorProps(errors['postal_code']) }
                >
                  <Input placeholder="Enter postal code" />
                </Form.Item>
              </Col>
              <Col span={ 24 }>
                <Form.Item name="own_vet" rules={ rules.own_vet } className="da-mb-16"
                  { ...getErrorProps(errors['own_vet']) }
                >
                  <Checkbox onChange={ (event) => {
                    setBooleanData({
                      ...booleanData,
                      own_vet: event.target.checked
                    })
                  } } id="petText" /><label htmlFor="petText"><strong>{ webData.pet_term_text }</strong></label>
                </Form.Item>
              </Col>
              <Col span={ 24 }>
                <h5 className="da-mb-24 color-lightgrey">Other Details</h5>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="microchip_no" rules={ rules.microchip_no } label="MicroChip" className="da-mb-16"
                  { ...getErrorProps(errors['microchip_no']) }
                >
                  <Input placeholder="Enter MicroChip #" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="vaccination_date" rules={ rules.vaccination_date } label="Vaccination Date Given" className="da-mb-16"
                  { ...getErrorProps(errors['vaccination_date']) }
                >
                  <DatePicker onChange={
                  (value) => {
                    // vacation date in if the is older than 1 year from booking date stop people booking 
                      let date = moment(value).add(1, 'years').format('YYYY-MM-DD');
                      if (moment(date).isBefore(moment().format('YYYY-MM-DD'))) {
                        message.error('Vaccination date must be greater than 1 year from today');
                        setDisableBooking(true);
                      }else{
                        setDisableBooking(false);
                      }
                  }
                } format="DD/MM/YYYY" className="da-w-100" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="kennel_cough_date" rules={ rules.kennel_cough_date } label="Kennel Cough Date Given" className="da-mb-16"
                  { ...getErrorProps(errors['kennel_cough_date']) }
                >
                  <DatePicker format="DD/MM/YYYY" className="da-w-100" />
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="neutered_spayed" rules={ rules.neutered_spayed } className="da-mb-16"
                  { ...getErrorProps(errors['neutered_spayed']) }
                >
                  <Checkbox onChange={ (event) => {
                    setBooleanData({
                      ...booleanData,
                      neutered_spayed: event.target.checked
                    })
                  }
                  } id="neutered" /> <label htmlFor="neutered"><strong>Neutered/Spayed</strong></label>
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="wormed" rules={ rules.wormed } className="da-mb-16"
                  { ...getErrorProps(errors['wormed']) }
                >
                  <Checkbox onChange={ (event) => {
                    setBooleanData({
                      ...booleanData,
                      wormed: event.target.checked
                    })
                  }
                  } id="wormed" /> <label htmlFor="wormed"><strong>Wormed</strong></label>
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="flee_tick_treatment" rules={ rules.flee_tick_treatment } className="da-mb-16"
                  { ...getErrorProps(errors['flee_tick_treatment']) }
                >
                  <Checkbox onChange={ (event) => {
                    setBooleanData({
                      ...booleanData,
                      flee_tick_treatment: event.target.checked
                    })
                  }
                  } id="tickFlee" /> <label htmlFor="tickFlee"><strong>Flee/Tick Treatment</strong></label>
                </Form.Item>
              </Col>
              <Col xs={ 24 } xl={ 8 }>
                <Form.Item name="insurance_name" rules={ rules.insurance_name } label="Insurance Name" className="da-mb-16"
                  { ...getErrorProps(errors['insurance_name']) }
                >
                  <Input placeholder="Enter Insurance Name" />
                </Form.Item>
              </Col>
              <Col span={ 24 } className="da-text-right">
                <SaveButton disabled={disableBooking} style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } text="Create Pet " form={ formName } key="create_button" htmlType="submit" state={ loader } />
              </Col>
            </Row>


          </Form>
        </div>
      </BodyComponent>
    </>
  );
}

export default CreateClientPet

const rules = {
  name: [
    { required: true, message: 'Please enter pet name', },
  ],
  email: [
    { type: "email", message: "The input is not valid email" },
    { required: true, message: "Please input pet email" },
  ],
  password: [
    { required: true, message: 'Please enter password', },
  ],
  pet_type_id: [
    { required: true, message: 'Please select type', },
  ],
  vet_name: [
    { required: true, message: 'Please enter name', },
  ],
  vet_emergency_phone: [
    { required: true, message: 'Please enter phone', },
  ],
  vet_phone: [
    { required: true, message: 'Please enter phone', },
  ],
  microchip_no: [
    
  ],
};
